//Hemlet
import { Helmet } from 'react-helmet';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//JUST ADDED 
import NavBarLogout from "pages/home/header/NavBarLogout"

//MOTION
import { motion, useScroll, useTransform } from 'framer-motion';

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useScreenContext } from 'context/AppContext/ScreenContext';
// import ImageGrid from 'components/ImageGrid/ImageGrid';
import ImageGridInteractive from 'components/ImageGrid/ImageGridInteractive';

//Components
import {PageContentContainer} from "components/Page/Elements"
import { ProviderHeroParallaxContent } from './components/ProviderHeroParallaxContent';
import FooterLoggedOut from 'components/Footer/FooterLoggedOut';
import { CALLTOACTION, SECTION, STANDARDBUTTON } from "components/standard";

//styles
import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'
import { NAVBAR_HEIGHT } from "components/constants";


import { useAuth } from "third_party/redux/selectors";


//CHANGE TO PROVIDER SIGNUP
//SIGNUP
import ProviderSignup from './signup/ProviderSignup';
import { useBusinessServiceContext } from 'api/Business/BusinessService/BusinessServiceAPIContext';

//SVG
import { ReactComponent as RocketIcon } from 'assets/provider/linegraph.svg';
import { ReactComponent as CalendarIcon } from 'assets/provider/calendar2.svg';
import { ReactComponent as GearsIcon } from 'assets/provider/gears2.svg';
import { ReactComponent as CoinsIcon } from 'assets/provider/money.svg';


//SUCCESS
import SignUpSuccess from 'pages/business/signup/SignUpSuccess';
import CheckoutError from 'pages/book/checkoutcomponents/checkoutError';


import { providerSignupAPI } from 'api/ProviderService/ProviderServiceNoDataAPI';
//=====================
//=====================
//====DATA MODELS======
//=====================
//=====================

// 4 x 3 (4 columns, 3 rows)
const images1 = [
    { src: 'images/Provider/experiences/musician3.png',caption:'DJ', gridColumn: 'span 2', gridRow: 'span 2' }, // Larger image
    { src: 'images/Provider/experiences/flowers4.png', caption:'Decorative Flowers',gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/food3.png',caption:'Charcuterie Board', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/balloon1.png',caption:'Balloon Arch', gridColumn: 'span 2', gridRow: 'span 1' }, // Larger image
    { src: 'images/Provider/experiences/photographer2.png', caption:'Photographer',gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/letters.png',caption:'Marquee Event Letters', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/musician8.png',caption:'Musicians (Duo)', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/foodtruck.png',caption:'Food Truck', gridColumn: 'span 1', gridRow: 'span 1' }
  ];
  const images1Mobile = [
    { src: 'images/Provider/experiences/musician3.png',caption:'DJ', gridColumn: '1 / span 3', gridRow: '1 / span 1' }, // Larger image
    { src: 'images/Provider/experiences/letters.png',caption:'Marquee Event Letters', gridColumn: '4 / span 1', gridRow: '1 / span 1' },
    { src: 'images/Provider/experiences/flowers4.png', caption:'Decorative Flowers',gridColumn: '1 / span 1', gridRow: '3 / span 1' },
    // { src: 'images/Provider/experiences/food3.png',caption:'Charcuterie Board', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/balloon1.png',caption:'Balloon Arch', gridColumn: '3 / span 2', gridRow: '2 / span 1' }, // Larger image
    { src: 'images/Provider/experiences/photographer2.png', caption:'Photographer',gridColumn: '1 / span 2', gridRow: '2 / span 1' },
    { src: 'images/Provider/experiences/musician8.png',caption:'Musicians (Duo)', gridColumn: '2 / span 3', gridRow: '3 / span 1' },
    // { src: 'images/Provider/experiences/foodtruck.png',caption:'Food Truck', gridColumn: 'span 1', gridRow: 'span 1' }
  ];


//   5 x 3 (5 columns, 4 rows)
  const images2 = [
    { src: 'images/Provider/experiences/photographer1.png', caption:'Photographer',gridColumn: 'span 2', gridRow: 'span 2' }, // Larger image
    { src: 'images/Provider/experiences/balloon2.png', caption:'Balloon Garland',gridColumn: '3 / span 3', gridRow: '1 / span 1' },
    // { src: 'images/Provider/experiences/videocamera1.png', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/musician1.png',caption:'Mariachi Band', gridColumn: 'span 2', gridRow: 'span 1' }, // Larger image
    { src: 'images/Provider/experiences/backdrop1.png',caption:'Decorative Backdrop', gridColumn: '3 / span 3', gridRow: '3 / span 1' },
    { src: 'images/Provider/experiences/art3.png',caption:'Art Decor', gridColumn: '1 / span 2', gridRow: '3 / span 1' },
    // { src: 'images/Provider/experiences/flowers5.png', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/decor3.png',caption:'Confetti', gridColumn: '5 / span 1', gridRow: '2 / span 1' }
  ];
  const images2Mobile = [
    // { src: 'images/Provider/experiences/photographer1.png', caption:'Photographer',gridColumn: 'span 2', gridRow: 'span 2' }, // Larger image
    { src: 'images/Provider/experiences/food3.png',caption:'Charcuterie Board',gridColumn: 'span 2', gridRow: 'span 2' }, // Larger image
    { src: 'images/Provider/experiences/flowers5.png',caption:'Plant Decor',gridColumn: '3 / span 3', gridRow: '1 / span 1' },
    // { src: 'images/Provider/experiences/videocamera1.png', gridColumn: 'span 1', gridRow: 'span 1' },
    { src: 'images/Provider/experiences/musician1.png',caption:'Mariachi Band', gridColumn: '3 / span 3', gridRow: '2 / span 1' }, // Larger image
    { src: 'images/Provider/experiences/backdrop1.png',caption:'Decorative Backdrop', gridColumn: '3 / span 3', gridRow: '3 / span 1' },
    { src: 'images/Provider/experiences/art3.png',caption:'Art Decor', gridColumn: '1 / span 2', gridRow: '3 / span 1' },
    // { src: 'images/Provider/experiences/flowers5.png', gridColumn: 'span 1', gridRow: 'span 1' },
    // { src: 'images/Provider/experiences/decor3.png',caption:'Confetti', gridColumn: '5 / span 1', gridRow: '2 / span 1' }
  ];


//   4 x 4 
  const images3 = [
    { src: 'images/Provider/experiences/musician5.png',caption:'Accoustic Musician (Guitarists)', gridColumn: '1 / span 3', gridRow: '1 / span 2' }, // Larger image
    { src: 'images/Provider/experiences/speaker.jpg',caption:'Tower Speakers', gridColumn: ' 4 / span 1', gridRow: '1/ span 2' },
    { src: 'images/Provider/experiences/videocamera1.png', caption:'Videographers',gridColumn: '3 / span 2', gridRow: '3 / span 2' },
    { src: 'images/Provider/experiences/kids1.png',caption:'Bounce House', gridColumn: '1 / span 2', gridRow: '3 / span 1' }, // Larger image
    { src: 'images/Provider/experiences/stringlight1.jpg',caption:'String Lighting Decor', gridColumn: '1 / span 1', gridRow: '4 / span 1' },
    { src: 'images/Provider/experiences/flowers5.png',caption:'Platn Decor', gridColumn: '2 / span 1', gridRow: '4 / span 1' },
    // { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' },
    // { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' }
  ];
  const images3Mobile = [
    { src: 'images/Provider/experiences/musician5.png',caption:'Accoustic Musician (Guitarists)', gridColumn: '1 / span 3', gridRow: '1 / span 2' }, // Larger image
    { src: 'images/Provider/experiences/speaker.jpg',caption:'Tower Speakers', gridColumn: ' 4 / span 1', gridRow: '1/ span 2' },
    { src: 'images/Provider/experiences/foodtruck.png',caption:'Food Truck',gridColumn: '3 / span 2', gridRow: '3 / span 2' },
    { src: 'images/Provider/experiences/stringlight1.jpg',caption:'String Lighting Decor', gridColumn: '1 / span 2', gridRow: '3 / span 1' }, // Larger image
    { src: 'images/Provider/experiences/videocamera1.png', caption:'Videographers', gridColumn: '1 / span 2', gridRow: '4 / span 1' },
    // { src: 'images/Provider/experiences/balloon2.png',caption:'Balloon Garland', gridColumn: '2 / span 1', gridRow: '4 / span 1' },
    // { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' },
    // { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' }
  ];
  
//   const images4 = [
//     { src: 'https://via.placeholder.com/800x600', gridColumn: 'span 2', gridRow: 'span 2' }, // Larger image
//     { src: 'https://via.placeholder.com/300x200', gridColumn: 'span 1', gridRow: 'span 1' },
//     { src: 'https://via.placeholder.com/300x300', gridColumn: 'span 1', gridRow: 'span 1' },
//     { src: 'https://via.placeholder.com/600x400', gridColumn: 'span 2', gridRow: 'span 1' }, // Larger image
//     { src: 'https://via.placeholder.com/400x300', gridColumn: 'span 1', gridRow: 'span 1' },
//     { src: 'https://via.placeholder.com/350x350', gridColumn: 'span 1', gridRow: 'span 1' },
//     { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' },
//     { src: 'https://via.placeholder.com/250x300', gridColumn: 'span 1', gridRow: 'span 1' }
//   ];

const Provider = (props) => {

    // const { isLoggedIn } = useAuth() //UPDATE TO PROVIDER
    const {isMobile, isMedium, isLarge} = useScreenContext()


    //CONTEXT
    const {serviceSignup} = useBusinessServiceContext()

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //References (useRef)
    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

    const GoghExperienceRef = useRef();
    const formRef = useRef(null)
    const servicesSectionRef = useRef(null);

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //References (useState)
    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //SIGNUP SHIT
    const [submitting, setSubmitting] = useState(false);
    const [isSignupOpen, setSignupOpen] = useState(false);
    const [serviceType, setserviceType] = useState('');
    const [invalidFields, setInvalidFields] = useState(new Set());
    const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //SCROLL
    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    const goToSignUpOfferings = () => {
    console.log("CLICKED!!!")
    servicesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
}
    

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX Services Section XXXXXXXX
    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

    const SectionHeroDividerSectionRef = useRef(null);
    const { scrollYProgress : scrollYProgressSectionHeroDividerSection } = useScroll({
      target: SectionHeroDividerSectionRef,
      offset:["start start" , "end end"],
    })

    const opacitySectionHeroDivider = useTransform(scrollYProgressSectionHeroDividerSection, [0,0.75,1],[1,0,0])


    //XXXXXXXXXXXXXXXXXXXXXXXXXX
      //XXXXXXX EXPERIENCE REFXXXX
        // Use Framer Motion's scroll progress
        const GoghExperienceScrollRef = useRef(null);
        const { scrollYProgress : scrollYProgressExperienceSection } = useScroll({
            target: GoghExperienceScrollRef,
            offset: ["start start", "end start"],
        });

        // Map vertical scroll progress to horizontal translation for container
        const translateX = useTransform(scrollYProgressExperienceSection, [0, 1], ["150dvw", "-300dvw"]);

        const scrollToTop = () =>{
            console.log("this is working?")
            // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
        } 


      //THIS IS WHAT'S NEEDED FOR SIGNUP SHEET
      const showErrorPopup = (message) => {
          setErrorMessage(message);
          setErrorPopupVisible(true);
        };
      
        const closeErrorPopup = () => {
          setErrorPopupVisible(false);
          setErrorMessage('');
        };

        const handleSuccessCloseSignup = () => {
            setShowSuccessPopup(false)
            setSignupOpen(false);
            //TODO: CLEAR FORM
            clearFormData()
        };

          const clearFormData = () => {


            formRef.current.reset();

          }




        const handleSubmit = async (userData) => {

            // event.preventDefault();
            const isFormValid = formRef.current.checkValidity();
            if (isFormValid) {
            console.log('Form is valid! Submitting...');
            setSubmitting(true); // Set submitting state to true
            try {
                await handleCreateRequest(userData);
              } catch (error) {
                setSubmitting(false); // Reset submitting state on error
                console.error('Error submitting form:', error);
              }
            } else {
                // Do something when the form is not valid
                const invalidFields = getInvalidFields(formRef);
                showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
            }
        };

        const getInvalidFields = (formRef) => {
            const formElements = formRef.current.elements;
            const invalidFields = [];
        
            for (let i = 0; i < formElements.length; i++) {
                const field = formElements[i];
                if (!field.checkValidity()) {
                    const fieldName = field.name || field.id;
                    if (fieldName) {
                        invalidFields.push(fieldName);
                    }
                }
            }
        
            return invalidFields;
        };
        const handleCreateRequest = async (data) => {
            try{
                // const result = await serviceSignup(data);
                const result = await providerSignupAPI(data)
                console.log('Request successful:', result);
                setShowSuccessPopup(true);
            }
            catch (error){
                console.error('Request failed:', error);
                // Show popup or any other error handling logic
                showErrorPopup(error.message);
            } finally {
                setSubmitting(false)
            }
          };
    return (
    <>
        <Helmet>
            <title>Provide on the Gogh: Offer your services and grow your business | GoghNow </title>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="GoghNow | Become a provider today" />
            <meta name="description" content="People everywhere are waiting to buy your services to elevate their event! Start providing on the Gogh today" />
            <meta property="og:image" content="images/cta-logo-one.png" />
        </Helmet>
        <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>
        <PageContentContainer>

            <ProviderHeroParallaxContent
                    imgUrl="images/Provider/experiences/provider2.png"
                    >
                <HeroFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <HeroFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <CTA>
                            <HeroDescription><b>GOGHNOW FOR PROVIDERS</b></HeroDescription>
                            <HeroText>Turn Your Passion Into Profit</HeroText>
                            <HeroTextSubDescription>Showcase your event services or products, and let clients come to you with requests to make their events unforgettable!</HeroTextSubDescription>
                            {/* Enjoy profit sharing and increased revenue, all managed from an easy-to-use dashboard. Sign up for a free business account today! */}
                            <SignUpButton onClick={goToSignUpOfferings} >Sign up now!</SignUpButton>
                            {/* <ActionButton>Sign In</ActionButton> */}
                        </CTA>
                    </HeroFlexItem1>
                </HeroFlexContainer>
            </ProviderHeroParallaxContent>


            <HeroTransitionSection ref={SectionHeroDividerSectionRef}>
                <HeroTransitionOverlay style={{opacity: opacitySectionHeroDivider}}></HeroTransitionOverlay>
                <HeroTransitionContainer>
                    <HeroTransitionHeading>If You Build It, They Will Come</HeroTransitionHeading>
                    <HeroTransitionDescription>See what our providers are providing</HeroTransitionDescription>
                </HeroTransitionContainer>
            </HeroTransitionSection>



            <GoghNowSection ref={GoghExperienceRef}>
                <GoghNowGalleryContainer ref={GoghExperienceScrollRef}>
                    <GoghNowGalleryStickyDiv> 
                        <GoghNowGalleryStickyContainerInner style={{x:translateX}}>
                            <GoghNowGalleryGridItem>
                                <SpecialImageGrid items={isMedium ? images1Mobile : images1} columns="repeat(4, 1fr)" rows="repeat(3, 1fr)" gap="15px" layout="custom1" />
                            </GoghNowGalleryGridItem>
                            <GoghNowGalleryGridItem>
                                <SpecialImageGrid items={isMedium ? images2Mobile : images2} columns="repeat(5, 1fr)" rows="repeat(3, 1fr)" gap="20px" layout="custom2" />
                            </GoghNowGalleryGridItem>
                            <GoghNowGalleryGridItem>
                                <SpecialImageGrid items={isMedium ? images3Mobile : images3} columns="repeat(4, 1fr)" rows="repeat(4, 1fr)" gap="20px" layout="custom2" />
                            </GoghNowGalleryGridItem>
                        </GoghNowGalleryStickyContainerInner>
                    </GoghNowGalleryStickyDiv>
                </GoghNowGalleryContainer>
            </GoghNowSection>
            



            <BenefitsSection>
                <BenefitsContainer>
                    <SectionHeader>We're Empowering Our Providers</SectionHeader>
                    <SectionHeaderDescription>Get requests directly with no extra work</SectionHeaderDescription>
                    <BenefitsContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <BenefitsImageContainer>
                                <CalendarIcon style={{ stroke: 'white',fill:"transparent", width: '100%', height: 'auto' }} />
                            </BenefitsImageContainer>
                            <h3>Flexibility</h3>
                            {/* Manage your schedule on your terms. */}
                            <p>You have full control over your availability</p>
                        </BenefitsContentItem>

                        <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <BenefitsImageContainer>
                                <CoinsIcon style={{ stroke: 'white',fill:"transparent", width: '100%', height: 'auto' }} />
                            </BenefitsImageContainer>
                            <h3>Earn Extra Income</h3>
                            <p>Make extra money on your terms—accept as many or as few bookings as you want</p>

                            {/* <p>Get discovered and booked faster, giving you more time to focus on delivering excellent services.</p> */}
                        </BenefitsContentItem>

                        <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <BenefitsImageContainer>
                                <GearsIcon style={{ stroke: 'white',fill:"transparent", width: '100%', height: 'auto' }} />
                            </BenefitsImageContainer>
                            <h3>Automate Your Operations</h3>
                            <p>Reduce administrative tasks with automated bookings, logistics and payments.</p>
                        </BenefitsContentItem>

                        <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <BenefitsImageContainer>
                                <RocketIcon style={{ stroke: 'white',fill:"white", width: '100%', height: 'auto' }} />
                            </BenefitsImageContainer>
                            <h3>Expand Your Reach</h3>
                            <p>Gain exposure to clients having private gatherings beyond your traditional network.</p>
                        </BenefitsContentItem>


                    </BenefitsContent>


                </BenefitsContainer>

            </BenefitsSection>




            <ServicesSection ref={servicesSectionRef}>
                <ServicesFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    {(isLarge) &&
                    <ServicesItemLeft>
                        <ServicesHeader>Sign up to become a provider today.</ServicesHeader>
                        {/* <ServicesSubHeader>Get access to event services and start earning for free. Upgrade for more features.</ServicesSubHeader> */}
                        <ImageContainer>
                            <img src='images/Home/pimage1.png' alt="space" />
                        </ImageContainer>
                    </ServicesItemLeft>
                    }
                    <ServicesItemRight>
                        <ServicesItemRightBoxBorderContainer>
                            <ServicesItemRightBoxBorderINTERNALContainer>
                                <ServicesHeaderRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Interested in becoming a provider?</ServicesHeaderRight>
                                <ServicesHeaderRightBig $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Sign up here</ServicesHeaderRightBig>
                                <ProviderSignup 
                                    // isOpen={true} 
                                    // onClose={handleCloseSignup} 
                                    onSubmit={handleSubmit}
                                    formRef={formRef}
                                    submitting={submitting}
                                    // name= {serviceType.name}
                                    // description = {serviceType.description}
                                />
                            </ServicesItemRightBoxBorderINTERNALContainer>
                        </ServicesItemRightBoxBorderContainer>
                    </ServicesItemRight>

                </ServicesFlexContainer>
            </ServicesSection>



            <FooterLoggedOut></FooterLoggedOut>

        </PageContentContainer>

        {showSuccessPopup && 
            <SignUpSuccess onClose={handleSuccessCloseSignup}/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}
    </>
    );
};


export default Provider;


//======================
//======================
//Random Repeatable Components
//======================
//======================

const GoghNowSection = styled.div`
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: white;
min-height: 100vh;
`



//======================
//======================
//NavBar
//======================
//======================
const SpecialNavBarLogout = styled(NavBarLogout)`
    position:fixed;
    background-color: transparent !important;
`
//======================
//======================
//Page Container
//======================
//======================
const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: black !important;;
`

//======================
//======================
//HERO SECTION
//======================
//======================
const HeroFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    align-items:center;
    justify-content: start;
    width: 100%;
    padding: 15px 36px;
    // max-width: 1280px;
    gap: 36px;
    flex-grow: 1;

`
const HeroFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
`
const HeroFlexItem2 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
`

const CTA = styled(CALLTOACTION)`
// padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
gap: 15px;
max-width: 600px;
align-items: start !important;
p, h1{
margin: 0px;
}
`;

const SignUpButton = styled.div`
color: #FFFFFF;
font-weight: 700;
font-size: 22px;
cursor: pointer;
background-color: #FBAE6290;
padding: 15px;
border-radius: 10px;

&:hover{
color: #FFFFFF;
background-color: #FBAE62;
}
`

const HeroText = styled.h1`
color: #FFFFFF;
// font-size: 3.5em;
font-weight: 200;
  font-size: 5em;
margin-top: 0px;
// line-height: 60px;
`
const HeroDescription = styled.p`
color: #FBAE62;
font-size : .8em;
margin: 0 0 24px;
line-height: 1.5em;
// letter-spacing: .75px;
max-width: 400px;
margin-bottom: 0px;
padding-bottom: 0px;
font-weight: 400;
// border-radius: 15px;
// background-color: white;
padding: 15px;
`

const HeroTextSubDescription = styled.h3`
color: #FFFFFF;
margin: 0 0 24px;
// line-height: 1.5em;
// letter-spacing: 1.5px;
font-weight: 200;
max-width: 500px;
margin-bottom: 25px;
`


//======================
//======================
//TRANSITION SECTION
//======================
//======================


const HeroTransitionSection = styled.div`
min-height:150dvh;
width: 100dvw;
background-color: white;
padding: 0px;
margin:0px;
`
const HeroTransitionOverlay = motion(styled.div`
position: absolute;
display: flex;
width: 100dvw;
height: 150dvh;
background: #64B0B8;
opacity: 1;
padding: 0px;
margin:0px;
`)
const HeroTransitionContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100dvw;
min-height: 150dvh;
padding: 36px;
margin:0px;
background-color: transparent;
position: relative;
text-align: center;
`

const HeroTransitionHeading = styled.h1`
text-align: center;
color: #3F3F3F;
margin-bottom: 10px;
`
const HeroTransitionDescription = styled.h2`
    color: #3F3F3F60;

`





//======================
//======================
//GALLARY SECTION
//======================
//======================


const GoghNowGalleryContainer = styled.div`
display: inline-block;
height: 200dvh;
width: 100dvw;
background-color: white; /*#f0f0f0;*/
padding-top: 0px;
top: 0px;
left: 0px;
`;

const GoghNowGalleryStickyDiv = styled.div`
  position: sticky;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  overflow-x:hidden;
    background-color: white;

`;

  const GoghNowGalleryStickyContainerInner = motion(styled.div`
    display: flex;
    top: 0;
    left: 0;
    min-height: 100dvh; // Full viewport height
    width: 300dvw;  // Extra-wide for horizontal scrolling effect
    background-color: purple;
    overflow-x: scrollable;
  `);

const GoghNowGalleryGridItem = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100dvw; // Full viewport width for each section
    height: 100dvh; // Full viewport height
    overflow: hidden;
    color: white;
    h2{
    color: white;
    }
    background-color: white; // Example background color for visual clarity
`

const SpecialImageGrid = styled(ImageGridInteractive)`

background-color: white;
gap: 10px;
padding: 10px;
`






//==================
//Services By Section
//===================
const ServicesSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
height: auto;
background-color: #eff6f9;
align-items: center;
`

const ServicesHeader = styled.h2`
padding: 50px 0px 0px;
`

const ServicesHeaderRight = styled.h4`
color:black;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
width: 100%;
text-align: ${props => {
    if (props.$isMobile) return 'center';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
padding: 25px 0px 10px 0px;
font-weight: 200;
// font-size: 22px;
`

const ServicesHeaderRightBig = styled.h2`
color:black;
// margin-bottom: 0px;
margin-top: 0px;
// padding-bottom: 0px;
width: 100%;
text-align: ${props => {
    if (props.$isMobile) return 'center';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
font-weight: 600;
font-size: 36px;
`
const ServicesSubHeader = styled.p`
// font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
margin: 0px;
`

const ServicesFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(1, 1fr)';
    if (props.$isLarge) return 'repeat(2, 1fr)';
    return 'repeat(2, 1fr)'; // Default to large if none match
  }};
    grid-auto-rows: 1fr;
    width: 100%;
    padding: 36px 36px;
    // max-width: 1280px;
    gap: 50px;
    // flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 769px) {
    gap: 25px;
    padding: 20px 0px;
    }

    @media (max-width: 450px) {
        gap: 0px;
        padding: 0px 0px;
    }
`

const ServicesItemLeft = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #EEE;
// border: 1px solid #CCC;
padding: 15px;
gap:25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 0px;
}
`

const ServicesItemRight = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
padding: 25px;
// box-shadow: 5px 5px 10px #00000060;;
background: transparent;
@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`


const ServicesItemRightBoxBorderContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 0 0 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
padding: 25px;
// border-radius: 25px;
box-shadow: 5px 5px 10px #00000060;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`

const ServicesItemRightBoxBorderINTERNALContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
width:100%;
height: auto;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
border: 2px solid #DDD;
padding: 25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`

const ServicesFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;

`
const ServicesFlexItem2 = styled.div`
    opacity: 0.75;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F60;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
    border: 4px dotted #00000060;

`

const ServicesFlexItem3 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
`

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`


//==================================
//BENEFITS JOINT
//==================================
const BenefitsSection = styled(GoghNowSection)`
min-height:100dvh;
width: 100dvw;
background-color: #64B0B8;
padding: 36px;
// margin:0px;
`

const BenefitsContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100%;
min-height: 200dvh;
padding: 0px;
margin:0px;
background-color: transparent;
position: relative;
gap:15px;
`

const BenefitsContent = styled('div', {
    shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
  })`
${mobileDisplayContentVertical};
display: ${props => {
    if (props.$isMobile) return 'flex';
    if (props.$isMedium) return 'grid';
    if (props.$isLarge) return 'grid';
    return 'grid'; // Default to large if none match
  }};
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
width:${props => {
    if (props.$isMobile) return '50%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '100%';
    return '100%'; // Default to large if none match
  }};
max-width: 1200px;
gap: 25px;
align-items: start;
justify-content: space-around;
padding: 25px 0px 250px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px 250px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px 150px 0px;
}
`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 1000px;
//   width: 100%;
//   padding: 50px 20px;
//   gap: 25px;
// `;


const BenefitsContentItem = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: auto; 
  flex: 1 1;
//   height: auto;
  height:100%;
  border-radius: ${props => {
    if (props.$isMobile) return '0px';
    return '25px'; // Default to large if none match
  }};
  background-color: ${props => {
    if (props.$isMobile) return 'transparent';
    if (props.$isMedium) return '#00000010';
    if (props.$isLarge) return 'transparent';
    return 'transparent'; // Default to large if none match
  }};

  border: ${props => {
    if (props.$isMobile) return 'transparent';
    if (props.$isMedium) return '1px solid #FFFFFF60';
    if (props.$isLarge) return '1px solid #FFFFFF60';
    return '1px solid #FFFFFF60'; // Default to large if none match
  }};
  border-bottom: ${props => {
    if (props.$isMobile) return '1px solid white';
    if (props.$isMedium) return 'none';
    if (props.$isLarge) return 'none';
    return 'none'; // Default to large if none match
  }};
  padding: 10px 5px;
  h3{
  margin-bottom: 0px;
  }
  p{

  }
`;

const BenefitsImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 1;
    width: 50%;
    padding-left: 5px;
    padding-right:5px;
}
`

const SectionHeader = styled.h2`
  line-height: 95%;
  padding: 5px 0px 50px 0px;
  color: black;
margin-bottom: 0px;
padding-bottom: 10px;
color: white;

`;

const SectionHeaderDescription = styled.h3`
display: flex;
    text-align: center;
    margin: 0px;
    color: #FFFFFF60;
    `
//==================================
//==================================
//==================================
