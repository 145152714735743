import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

// Define props to include 'style' and motion props
export const FlipLink = forwardRef<HTMLDivElement, {
  children: string;
  href: string;
  style?: React.CSSProperties; // Add style prop explicitly
} & React.ComponentProps<typeof motion.div>>(
  ({ children, href, style, ...props }, ref) => {
    return (
      <div>
        <TextDiv
          initial={{ y: 0 }}
          whileHover={{ y: 20 }}
          style={style} // Pass style directly
          ref={ref} // Forward the ref to TextDiv
          {...props} // Spread additional motion-compatible props
        >
          {children}
        </TextDiv>
      </div>
    );
  }
);

const TextDiv = styled(motion.div)`
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold; // Corrected typo in 'font-weight'
`;

// import React from 'react';
// import styled from '@emotion/styled';
// import { motion } from 'framer-motion';

// // Define props to include 'style' and motion props
// export const FlipLink = ({
//   children,
//   href,
//   style,
//   ...props
// }: {
//   children: string;
//   href: string;
//   style?: React.CSSProperties; // Add style prop explicitly
// } & React.ComponentProps<typeof motion.div>) => {
//   return (
//     <div>
//     <TextDiv
//       initial={{ y: 0 }}
//       whileHover={{ y: 20 }}
//       style={style} // Pass style directly
//       {...props} // Spread additional motion-compatible props
//     >
//       {children}
//     </TextDiv>
//     </div>
//   );
// };

// const TextDiv = styled(motion.div)`
//   position: relative;
//   display: block;
//   overflow: hidden;
//   white-space: nowrap;
// //   color: black;
//   font-weight: bold; // Corrected typo in 'font-weight'
// `;
