import {useState, useEffect, useRef } from "react";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
import { motion, useScroll, useTransform } from "framer-motion";

const IMG_PADDING = 0;

export const TextParallaxContent = ({imgUrl, subheading, heading, children, ...props}) => {


    const ContainerRef = useRef(null);
    const { scrollYProgress : scrollYProgressHeroSection } = useScroll({
      target: ContainerRef,
      offset:["start start" , "end center"],
    })

    const scale = useTransform(scrollYProgressHeroSection, [0,1],[1,0.80])
    const opacity = useTransform(scrollYProgressHeroSection, [0,1],[1,0])

return (
    <Container {...props}>
        <ImageContainer>
            <StickyImage $imgUrl={imgUrl} style={{scale}} ref={ContainerRef}>
                <StickyImageOverlay style={{opacity}}/>
            </StickyImage>
            <OverlayContainer style={{scale, opacity}}>
                {children}
            </OverlayContainer>
        </ImageContainer>
        {/* {children} */}
    </Container>
)

};

//TextParallaxContent
const Container = styled(motion.div)`
display:flex;
padding: 0px ${IMG_PADDING}px;
background-color: black;
`

const ImageContainer =styled(motion.div)`
padding-top: ${IMG_PADDING};
display:inline-block;
position: relative;
height: 150vh; //gives us a space to scroll the image
width: 100vw;
background-color: black;
`

const StickyImage = styled(motion.div, {
    shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
  })`
top: ${IMG_PADDING}px;
display: flex;
position: sticky;
overflow: hidden;
width: auto;
height: calc(100vh - (${IMG_PADDING}px * 2));
background-image: ${props => props.$imgUrl ? `url(${props.$imgUrl})` : ''};  // bacgkround image
background-size: cover; /* Cover the entire section */
background-position: center; /* Center the background image */
overflow:hidden;
border-radius: 5%;
background-color: black;

`

const StickyImageOverlay = styled(motion.div)`
display:flex;
background-color: #00000090;
width: 100%;
height: 100%:
`





const OverlayContainer = styled(motion.div)`
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
position: absolute;
height: 100vh;
width: 100vw;
left: 0px;
top:0px;

`