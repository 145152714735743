import styled from "styled-components"
import { NAVBAR_HEIGHT } from "components/constants";

import { useBusinessAppNavBarContext } from "../Context/BusinessAppNavBarContext";
import BusinessProfileMenu from "./BusinessProfileMenu";
// import { useCart } from "third_party/redux/selectors"
// import SearchFunctions from "utility/searchFunctions"

//HOW TO UPDATE
///SEND IMAGE FOR PROFILE
//GET NOTIFICATIONS and Update Indicator
//remove {onClick}
const ProfileMenuButton = () => {
    const countCartItems = 0//SearchFunctions.countCartItems(cart); // Safely check the length or default to 0

    //Context
    const {toggleProfileMenu, closeProfileMenu, isProfileMenuOpen} = useBusinessAppNavBarContext()

    const handleMenuClick = () => {
            //   const handleProfileMenuButtonPressed = () => {
        toggleProfileMenu()
        console.log('menu getting toggled baby')
    //   }
    }
return (
    <>
    <BlockContainer>
    <Container>
        <Button onClick={handleMenuClick}>
            <IconContainer>
                <img src="/images/Business/app/user.png" alt="profile-menu" />
            </IconContainer>
        </Button>
        <Indicator>{countCartItems}</Indicator>
    </Container>
    {(isProfileMenuOpen) && 
        <>
        {/* <p>BITCH</p> */}
        <SpecialBusinessProfileMenu/>
        </>
    }
    </BlockContainer>
    </>
)


}
export default ProfileMenuButton;
const Container = styled.div`
display: block;
position: relative;
height: auto;
width: auto;
`
const Button = styled.div`

    display: flex;
    padding: 2.5px 2.5px;
    background-color: #CCCCCC60;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    &:hover {
        background-color: #FBAE62;
   }
`

const IconContainer = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
display: flex;

img {
    display: flex;
    width:65%;
    height: 65%;
    color: black;
}
`

const Indicator = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: red;
color: white;
position: absolute;
top: 0px;
right: calc(0px - 10px);
aspect-ratio: 1/1;
height: 20px;
width: 20px;
border-radius: 75%;
padding: 2px 4px;
`

const SpecialBusinessProfileMenu = styled(BusinessProfileMenu)`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px;
  z-index: 9999;
  width: 300px !important;
`;

const BlockContainer = styled.div`
display: block;
position:relative;
height: auto;
width: auto;
`