const unsortedBlogs = [
    {
      id: 1,
      type:'header',
      image:"images/blog/blog1.jpg",
      author: "Ajalon E.",
      date: "2024-12-01",
      tag: 'Founder',
      heading: "Why We Made The Events Blog",
      subheading: "The story on why we started this resource for everyday people",
      content: [
        {
          type: "p",
          text: "<strong>Experiences</strong> are at the heart of everything we do. Whether it’s attending an unforgettable event, discovering a new destination, or stepping into an awe-inspiring space, we all cherish the magic of a great experience. Yet, creating those experiences? That’s the hard part.",
          plainText: "Experiences are at the heart of everything we do. Whether it’s attending an unforgettable event, discovering a new destination, or stepping into an awe-inspiring space, we all cherish the magic of a great experience. Yet, creating those experiences? That’s the hard part."
        },
        {
          type: "p",
          text: "The challenges aren’t new:",
          plainText: "The challenges aren’t new:"
        },
        {
          type: "list",
          items: [
            "<strong>Access to resources:</strong> Finding the right information and reliable providers can feel impossible.",
            "<strong>Lack of inspiration:</strong> How do you bring an idea to life if you haven’t experienced something similar yourself?",
            "<strong>Complexity of execution:</strong> Even with the vision, making it a reality is often overwhelming."
          ],
          plainItems: [
            "Access to resources: Finding the right information and reliable providers can feel impossible.",
            "Lack of inspiration: How do you bring an idea to life if you haven’t experienced something similar yourself?",
            "Complexity of execution: Even with the vision, making it a reality is often overwhelming."
          ]
        },
        {
          type: "p",
          text: "That’s why we created <i>The Events Blog</i>.",
          plainText: "That’s why we created The Events Blog."
        },
        {
          type: "h2",
          text: "A Resource for Creating Experiences",
          plainText: "A Resource for Creating Experiences"
        },
        {
          type: "p",
          text: "Unlike traditional event tools like management systems or ticketing platforms, this blog is different. It’s a straightforward, inspiring, and practical resource to help you execute your event vision reliably and efficiently.",
          plainText: "Unlike traditional event tools like management systems or ticketing platforms, this blog is different. It’s a straightforward, inspiring, and practical resource to help you execute your event vision reliably and efficiently."
        },
        {
          type: "p",
          text: "We’re here for everyone—from those planning large celebrations to those organizing smaller “micro-experiences” like department luncheons, casual gatherings, or intimate meetings. These smaller moments matter just as much, and we aim to simplify every step of the process.",
          plainText: "We’re here for everyone—from those planning large celebrations to those organizing smaller “micro-experiences” like department luncheons, casual gatherings, or intimate meetings. These smaller moments matter just as much, and we aim to simplify every step of the process."
        },
        {
          type: "h2",
          text: "Why This Matters to Us",
          plainText: "Why This Matters to Us"
        },
        {
          type: "p",
          text: "In our travels and careers, we’ve not only had the privilege of experiencing incredible spaces but also creating them for others. Along the way, we’ve learned what it takes to make a space unforgettable. Now, we’re sharing those insights with you.",
          plainText: "In our travels and careers, we’ve not only had the privilege of experiencing incredible spaces but also creating them for others. Along the way, we’ve learned what it takes to make a space unforgettable. Now, we’re sharing those insights with you."
        },
        {
          type: "p",
          text: "We also draw inspiration from unexpected places, applying lessons from other industries to reimagine how events are created and experienced. It’s about breaking free from outdated approaches and making the extraordinary achievable.",
          plainText: "We also draw inspiration from unexpected places, applying lessons from other industries to reimagine how events are created and experienced. It’s about breaking free from outdated approaches and making the extraordinary achievable."
        },
        {
          type: "h2",
          text: "What You’ll Find Here",
          plainText: "What You’ll Find Here"
        },
        {
            type:"p",
            text:"On <i>The Events Blog</i>, you'll discover:",
            plainText: " On The Events Blog, you'll discover:"
        },
        {
          type: "list",
          items: [
            "<strong>Expert insights</strong> to spark your creativity.",
            "<strong>The latest trends and ideas</strong> in the event space.",
            "<strong>Innovations and technologies</strong> shaping the future of experiences.",
            "<strong>Practical tips</strong> for creating both big and small moments with ease."
          ],
          plainItems: [
            "Expert insights to spark your creativity.",
            "The latest trends and ideas in the event space.",
            "Innovations and technologies shaping the future of experiences.",
            "Practical tips for creating both big and small moments with ease."
          ]
        },
        {
          type: "p",
          text: "Whether you’re planning an unforgettable milestone or a simple gathering, our goal is to become the go-to resource for all your event knowledge and needs.",
          plainText: "Whether you’re planning an unforgettable milestone or a simple gathering, our goal is to become the go-to resource for all your event knowledge and needs."
        },
        {
          type: "h2",
          text: "Looking Ahead",
          plainText: "Looking Ahead"
        },
        {
          type: "p",
          text: "We know that creating events can be daunting, but it doesn’t have to be. With The Events Blog, we’re here to inspire and empower you to bring your vision to life. It’s about creating spaces, moments, and memories that matter—and doing it in a way that’s simple and enjoyable.",
          plainText: "We know that creating events can be daunting, but it doesn’t have to be. With The Events Blog, we’re here to inspire and empower you to bring your vision to life. It’s about creating spaces, moments, and memories that matter—and doing it in a way that’s simple and enjoyable."
        },
        {
          type: "p",
          text: "Thank you for joining us on this journey. We can’t wait to share more with you and help you turn your ideas into experiences.",
          plainText: "Thank you for joining us on this journey. We can’t wait to share more with you and help you turn your ideas into experiences."
        },
        {
          type: "p",
          text: "Here’s to creating your next unforgettable event,",
          plainText: "Here’s to creating your next unforgettable event,"
        },
        {
          type: "p",
          text: "The Team at GoghNow",
          plainText: "The Team at GoghNow"
        }
      ]
    },
    {
        id: 2,
        type:'standard',
        image: "images/blog/blog2.jpg",
        image_alt:"holiday image",
        author: "GoghNow Team",
        date: "2024-12-04",
        tag: "Event Tips",
        heading: "Last-Minute Holiday Event Planning Made Easy",
        subheading: "Three steps to streamline your planning and save valuable time",
        content: [
          {
            type: "p",
            text: "The holidays are fast approaching, and if you’re scrambling to pull together an event, don’t worry—there’s still time to make it memorable! Here are three key steps to streamline your planning process and save valuable time:",
            plainText: "The holidays are fast approaching, and if you’re scrambling to pull together an event, don’t worry—there’s still time to make it memorable! Here are three key steps to streamline your planning process and save valuable time:"
          },
          {
            type: "h2",
            text: "1. Prioritize Essentials First",
            plainText: "1. Prioritize Essentials First"
          },
          {
            type: "p",
            text: "Focus on the key elements your event absolutely needs—venue, guest list, and food. Book a space that already has tables, chairs, and holiday decor to reduce setup time. When it comes to catering, opt for simple menus or pre-prepared platters from local vendors who specialize in last-minute orders.",
            plainText: "Focus on the key elements your event absolutely needs—venue, guest list, and food. Book a space that already has tables, chairs, and holiday decor to reduce setup time. When it comes to catering, opt for simple menus or pre-prepared platters from local vendors who specialize in last-minute orders."
          },
          {
            type: "h2",
            text: "2. Leverage Technology",
            plainText: "2. Leverage Technology"
          },
          {
            type: "p",
            text: "Use event planning platforms or apps to source services and vendors quickly. These tools can help you find everything from entertainment to decor with minimal effort. Bonus: find a platform that offers everything you need in a one-stop shop, so you can be confident in your choice and save time.",
            plainText: "Use event planning platforms or apps to source services and vendors quickly. These tools can help you find everything from entertainment to decor with minimal effort. Bonus: find a platform that offers everything you need in a one-stop shop, so you can be confident in your choice and save time."
          },
          {
            type: "h2",
            text: "3. Delegate, Delegate, Delegate",
            plainText: "3. Delegate, Delegate, Delegate"
          },
          {
            type: "p",
            text: "Don’t do it all yourself! Assign tasks like managing RSVPs or picking up rentals to trusted friends, family, or team members. By focusing on what matters most and utilizing resources efficiently, you can create a memorable holiday gathering, even on a tight timeline.",
            plainText: "Don’t do it all yourself! Assign tasks like managing RSVPs or picking up rentals to trusted friends, family, or team members. By focusing on what matters most and utilizing resources efficiently, you can create a memorable holiday gathering, even on a tight timeline."
          },
          {
            type: "p",
            text: "Start now, and let the holiday cheer begin!",
            plainText: "Start now, and let the holiday cheer begin!"
          }
        ]
      }
  ];

// export const blogs = [
//     {
//       id: 1,
//       heading: "How to Plan an Event",
//       subheading: "Tips and Tricks for a Successful Event",
//       author: "Jacob Elliott",
//       date: "2024-12-01",
//       tag: 'Founder',
//       content: [
//         { type: "p", text: "Planning an event can be overwhelming" },
//         { type: "list", items: ["Set a budget", "Choose a venue"] },
//       ],
//     },
//     {
//       id: 2,
//       heading: "Choosing the Right Venue",
//       subheading: "What to Consider When Picking a Location",
//       author: "Justin Smith",
//       date: "2024-12-02",
//       tag: 'Company',
//       content: [
//         { type: "p", text: "Finding the perfect venue takes effort" },
//       ],
//     },
//   ];
  
  //Content can be HTML


  export const blogs = unsortedBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));
