import {useNavigate, useLocation } from 'react-router-dom';
import { useState,useRef, useEffect } from 'react';

import BookNavBar from './BookNavBar'

//Context
import { useScreenContext } from 'context/AppContext/ScreenContext';
import RequestServiceAPI from 'api/User/RequestService/RequestServiceAPI';
//elements
import styled from 'styled-components';
// import { PageContainer, PageContentContainer } from "components/Page/Elements";
import { NAVBAR_HEIGHT, SECTION_PADDING } from "components/constants";
import LocationInputItem from 'apps/UserAppComponents/SearchComponents/LocationSearchComponent/LocationInputItem';
import { ActionButton } from 'components/standard';

//Components
import CheckoutLocation from './checkoutcomponents/checkoutLocation';
import CheckoutServices from './checkoutcomponents/checkoutServices';
import CheckoutDate from './checkoutcomponents/checkoutDate';
import CheckoutDuration from './checkoutcomponents/checkoutDuration';
import CheckoutUserInfo from './checkoutcomponents/checkoutUserInfo';
import CheckoutDetails from './checkoutcomponents/checkoutDetails';
import CheckoutSuccess from './checkoutcomponents/checkoutSuccess';
import CheckoutError from './checkoutcomponents/checkoutError';
// import { submitServicesRequest } from 'api/APICalls';

//NEW
import { useSearch } from 'third_party/redux/selectors';
import { useCart } from 'third_party/redux/selectors';
import { useUserActions } from 'third_party/redux/user/userAction';
//user
import { useUser } from 'third_party/redux/selectors';

import SearchFunctions from 'utility/searchFunctions';

import { AuthObjectClass } from 'model/objectAuth';
import { useAuth} from 'third_party/redux/selectors';
const Book = (props) => {
    const navigate = useNavigate();

    //auth stuff
    const authState = useAuth()
    const auth = new AuthObjectClass(authState)

    //VALIDATION FIELDS
    const [invalidFields, setInvalidFields] = useState(new Set());
    const [submitting, setSubmitting] = useState(false);

    //Context to Save
    const {isMobile, isMedium, isLarge} = useScreenContext()
    //user
    const {USER_UPDATE} = useUserActions()

    const user = useUser() //USER
    const searchCriteria = useSearch().current //SEARCH
    const cartItems = useCart() //SEARCH
    //TODO: UPDATE USER
    const countCartItems = SearchFunctions.countCartItems(cartItems); // Safely check the length or default to 0
    const priceCartItems = SearchFunctions.priceCartItems(cartItems);


    const [requestDetails, setRequestDetails] = useState({
        budget : 0,
        details: '',
        promo_code: '',
    })

    // const [guestDetails, setGuestDetails] = useState({
    //     name : '',
    //     email: '',
    //     phone: '',
    // })

    // context to display



    const handleInputChange = (e, newValue) => {

        const { id, value } = e.target;
        const newValueOrEvent = newValue !== undefined ? newValue : value;
        const updateProperty = {[id]:newValueOrEvent}
        console.log("updateProperty | ",updateProperty)

        USER_UPDATE(updateProperty)
        // setGuestDetails((prevState) => ({
        //     ...prevState,
        //     [id]: newValueOrEvent,
        // }));
    };

    const handleDetailsInputChange = (e, newValue) => {

        const { id, value } = e.target;
        const newValueOrEvent = newValue !== undefined ? newValue : value;
        console.log("id: ",id," | ", value)

            setRequestDetails((prevState) => ({
                ...prevState,
                [id]: newValueOrEvent,
            }));
    };

    const formRef = useRef(null)
    
    const onSubmit = async (event) => {
        console.log('BUDGET IS: ',requestDetails)

        if (requestDetails.budget === 0){
            showErrorPopup(`Please update your budget`);
            return
        }
        if(requestDetails.details.length===0){
            showErrorPopup(`Please add details to your event`);
            return
        }
        event.preventDefault();
        const isFormValid = formRef.current.checkValidity();
        if (isFormValid) {
        // Do something when the form is valid
        console.log('Form is valid! Submitting...');
        setSubmitting(true); // Set submitting state to true
        try {
            await handleCreateRequest();
          } catch (error) {
            setSubmitting(false); // Reset submitting state on error
          }
        } else {
        // Do something when the form is not valid
        const invalidFields = getInvalidFields(formRef);
        showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
    }
    };

    const getInvalidFields = (formRef) => {
        const formElements = formRef.current.elements;
        const invalidFields = [];
    
        for (let i = 0; i < formElements.length; i++) {
            const field = formElements[i];
            if (!field.checkValidity()) {
                const fieldName = field.name || field.id;
                if (fieldName) {
                    invalidFields.push(fieldName);
                }
            }
        }
    
        return invalidFields;
    };

    const handleCreateRequest = async () => {
        try{
            const result = await RequestServiceAPI.createRequest(searchCriteria, cartItems, user, requestDetails);
            console.log('Request successful:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            showErrorPopup(error.message);
        } finally {
            setSubmitting(false)
        }
      };



    const handleBackPressed = () => {
        navigate(-1); // -1 will navigate to the previous page
    }

    const handlePlaceSelection = () => {
        //popup to change location
        //component me
        console.log('popping up window to change location here :)')
    }
    
    //error Shit
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const showErrorPopup = (message) => {
        setErrorMessage(message);
        setErrorPopupVisible(true);
      };
    
      const closeErrorPopup = () => {
        setErrorPopupVisible(false);
        setErrorMessage('');
      };

    //USE EFFECT HOOKS
    // useEffect(() => {
    //     if (createRequestResult !== null) { // Ensure checking for non-null value
    //       createRequestSubmitted(createRequestResult);
    //     }
    //   }, [createRequestResult]);
    

    // const createRequestSubmitted = (response) => {
    //     console.log('Request Submitted WITH RESPONSE:', response);
    //     // Perform any additional actions needed after request submission

    // }

    console.log("USER", user)
    return (
        <PageContainer>
            <BookNavBar/>
            <PageContentContainer>
                <Container>
                    <HeaderPanel>
                        <button onClick={handleBackPressed}>←</button>
                        <h1>Confirm and request</h1>
                    </HeaderPanel>
                </Container>
                <SplitContainer>
                    <SplitContainerChild>
                        <BoxParentNoBorder>
                            <SubHeading>Your Event</SubHeading>
                            {(isMobile) &&
                                <CheckoutLocation item={searchCriteria.location} onSelect={handlePlaceSelection} ></CheckoutLocation>
                            }
                            <CheckoutDate date={SearchFunctions.displayAbbreviatedMonthDayTime(searchCriteria.date)}/>
                            <SpaceEmpty/>
                            <CheckoutDuration duration={SearchFunctions.displayDuration(searchCriteria.duration)}/>
                            <SpaceEmpty/>
                                
                                {(isMobile) &&
                                <>
                                    <Space/>
                                    <BoxParentMobile>
                                        <PanelTitle>Services</PanelTitle>
                                        <CheckoutServices items={cartItems}/>
                                        <SpaceEmpty/>
                                        {/* <Space/> */}
                                    </BoxParentMobile>
                                </>
                                }
                            <Space/>

                            
                                <form id="FormID" ref={formRef} onSubmit={onSubmit}>
                                    <SubHeading>Details</SubHeading>
                                    <CheckoutDetails data={requestDetails} handleInputChange={handleDetailsInputChange}/>
                                    <Space/>

                                    {(auth.isLoggedInAsUser) &&
                                        <SubHeading>{user.first_name}, continue to checkout</SubHeading>
                                    }

                                    {(!auth.isLoggedInAsUser) &&
                                        <>
                                            <SubHeading>Continue as guest</SubHeading>
                                            <CheckoutUserInfo user={user} handleInputChange={handleInputChange}/>
                                        </>
                                    }
                                </form>

                            <CheckOutContainer>
                                <CheckOutButton
                                    onClick={onSubmit}
                                    disabled={submitting}
                                    style={{ width: '100% important!' }}>
                                    {submitting ? 'Submitting...' : `Submit Request (${countCartItems}) - ${priceCartItems}`}
                                </CheckOutButton> 
                            </CheckOutContainer>
                            <SpaceEmpty/>
                            <SpaceEmpty/>
                            <SpaceEmpty/>
                            <SpaceEmpty/>
                            <SpaceEmpty/>

                        </BoxParentNoBorder>
                    </SplitContainerChild>

                    {(isMedium || isLarge) &&
                    <SplitContainerChild>
                        <BoxParent>
                            <PanelTitle>Checkout</PanelTitle>
                            <CheckoutLocation item={searchCriteria.location} onSelect={handlePlaceSelection} ></CheckoutLocation>
                            <Space/>
                            <CheckoutServices items={cartItems}/>
                            <SpaceEmpty/>
                            {/* <Space/> */}
                        </BoxParent>
                    </SplitContainerChild>
                    }   


                </SplitContainer>

                
            </PageContentContainer>
        {(showSuccessPopup) && 
            <CheckoutSuccess/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}
        </PageContainer>
    );
};

export default Book;
const PageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const PageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
`
const Space = styled.div`
display:flex;
width:100%;
height: 1px;
background-color: #00000040;
margin: 15px 0px;
`

const SpaceEmpty = styled.div`
display:flex;
width:100%;
height: 1px;
// background-color: #000000;
margin: 7.5px 0px;
`
const HeaderPanel = styled.div`
display: flex;
flex-direction:column;
width: 100%;
height: ${NAVBAR_HEIGHT};
// justify-content: start;
// align-items: start;
gap: 15px;
color: black;
height: auto;
margin: 15px;
padding: 15px;
button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
h1{
    color: black !important;
}
`
const SubHeading = styled.h2`
color: black;
margin-bottom: 0px;
`
const Container = styled.div`
display: flex;
padding:0px 15px;/*${SECTION_PADDING};*/
`

const SplitContainer = styled.div`
display: flex;
padding:0px 15px;/*${SECTION_PADDING};*/
gap :15px;
`
const SplitContainerChild = styled.div`
display: flex;
flex-direction: column;
flex: 1 1 calc(50% - 15px);
// overflow-y: scroll;

`

const BoxParentNoBorder = styled.div`
display: flex;
flex-direction: column;
// border: 1px solid #00000060;
border-radius: 25px;
margin: 15px;
padding:15px;
position: -webkit-sticky; /* For Safari */
position: sticky;

`

const BoxParent = styled.div`
top:0px;
position: -webkit-sticky; /* For Safari */
position: sticky;
display: flex;
flex-direction: column;
border: 1px solid #00000060;
border-radius: 25px;
margin: 15px;
padding:15px;

`
const BoxParentMobile = styled.div`
position:relative;
display: flex;
flex-direction: column;
border: 1px solid #00000060;
border-radius: 25px;
margin: 15px;
padding:15px;
`


const PanelTitle = styled.h1`
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
`


const CheckOutContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: ${NAVBAR_HEIGHT};
// background-color: #CCC;
`

const CheckOutButton = styled(ActionButton)`
width: 80% !important;
padding: 0px !important;
margin: 0px !important;
justify-content: center;
`

// Define the ErrorPopup component
const ErrorPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid red;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  p {
    margin: 0 0 10px;
  }

  button {
    padding: 5px 10px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
  }
`;