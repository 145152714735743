import React from 'react';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'


//Compoennts two
import { Link } from "react-router-dom";
// import { blogs } from "./blogsData";
import { BlogPreview } from "components/Blogs/BlogPreview";
import { useScreenContext } from 'context/AppContext/ScreenContext';


// Grid Section with dynamic layout
const GridSection = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.columns || 'repeat(4, 1fr)'};
  grid-template-rows: ${(props) => props.rows || 'repeat(3, 1fr)'};
  gap: ${(props) => props.gap || '15px'};
//   padding-right: ${(props) => props.gap || '15px'};
//   overflow: hidden;
`;

// GridItem: Container for both media and text
const GridItem = styled.div`
  width: 100%;
  height: 100%;
//   overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'span 1'};
  grid-row: ${(props) => props.gridRow || 'span 1'};
  // Ensure we do not pass these as plain DOM props
`;

// Styled Image
// const GridItemImage = styled.img`
//   width: 100%;
//   height: 100%;
//   min-width: 100%;
//   min-height: 100%;
//   object-fit: cover; // Ensures the image fills the space without distorting
//   object-position: center; // Ensures the image is centered within its container
//   // border-radius: 8px;
//   overflow: hidden;

//   // Dynamically apply grid column and row values
//   // grid-column: ${(props) => props.gridColumn || 'span 1'};
//   // grid-row: ${(props) => props.gridRow || 'span 1'};
// `;

// Styled Video
// const GridItemVideo = styled.video`
//   width: 100%;
//   height: 100%;
//   min-width: 100%;
//   min-height: 100%;
//   object-fit: cover;
//   object-position: center;
//   overflow: hidden;
// `;

// const GridItemVideo = styled.video`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center;
//   overflow: hidden;
//   border: none; // Ensure no outline or border appears
//   outline: none;
//   display: block; // Prevent it from popping out of its container
// `;

// // Caption or Text Inside the Grid Item
// const GridItemText = styled.div`
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   color: white;
//   font-size: 16px;
//   background-color: rgba(0, 0, 0, 0.6); // Semi-transparent background for text visibility
//   padding: 5px;
//   border-radius: 4px;
// `;

// List of file extensions for images and videos
// const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
// const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'];

// const isVideo = (src) => {
//   const ext = src.split('.').pop().toLowerCase(); // Extract the file extension
//   return videoExtensions.includes(ext);
// };




const BlogGrid = ({ blogs, gap, sizes, ...props }) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const columns = getColumns(isMobile,isMedium,isLarge)
    const rows = getRows(isMobile,isMedium,isLarge)

//   React.useEffect(() => {
//     const videos = document.querySelectorAll('video');
//     videos.forEach((video) => {
//       video.muted = true; // Ensure it's muted
//       video.play().catch(() => {
//         console.log('Autoplay failed on mobile');
//       });
//     });
//   }, []);

//SIZes is an array size of items that the grid will use to 
return (
    <GridSection columns={columns} rows={rows} gap={gap} {...props}>
      {blogs.map((blog, index) => {
        // const size = sizes[index] || { gridColumn: 'span 1', gridRow: 'span 1' }; // Default size if not specified
        const blogType = determineBlogType(blog,index)
        const { gridColumn, gridRow } = getGridStylesFromBlogType(blogType,isMobile,isMedium,isLarge);
        return (
          <React.Fragment key={blog.id}>
            <GridItem
            //   gridColumn={size.gridColumn}
            //   gridRow={size.gridRow}
            gridColumn={gridColumn}
            gridRow={gridRow}
            >
              <LinkSpecial to={`/blog/${formatHeading(blog.heading)}`} style={styles.link}>
                <BlogPreview
                  heading={blog.heading}
                  subheading={blog.subheading}
                  date={blog.date}
                  type={blogType}
                  content={blog.content}
                  tag = {blog.tag}
                  image = {blog.image}
                >
                    {/* ANYTHING HERE */}
                </BlogPreview>
              </LinkSpecial>
            </GridItem>
          </React.Fragment>
        );
      })}
    </GridSection>
  );
  
};

export default BlogGrid;

const LinkSpecial = styled(Link)`
width: 100%;
height: 100%;
display: flex;
    // border: 1px solid black;
`


  const styles = {
    // gridContainer: {
    //   display: "grid",
    //   gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    //   gap: "16px",
    //   padding: "16px",
    // },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
  };





  const formatHeading = (heading) => {
    return heading.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with dashes, keep original dashes
};



  const getColumns = (isMobile, isMedium, isLarge) => {
    return (isMobile || isMedium) ? "repeat(1, 1fr)" : "repeat(3, 1fr)";
  };
  
  const getRows = (isMobile, isMedium) => {
    return (isMobile || isMedium) ? "auto" : "repeat(2, 1fr)";
  };
  



  
  const determineBlogType = (blog, index) => {
    // The first blog is always a header
    if (index === 0) {
      return "header";
    }
  
    // // Additional criteria (e.g., based on the tag)
    // if (blog.tag === "Founder") {
    //   return "header";
    // }
  
    // Default to "standard"
    return "standard";
  };


  const getGridStylesFromBlogType = (type, isMobile,isMedium, isLarge) => {
    if (type === "header") {
      if (isMobile || isMedium) {
        return {
          gridColumn: "span 1", // Span 1 column
          gridRow: "span 2",    // Span 2 rows
        };
      }
      if (isLarge) {
        return {
          gridColumn: "span 2", // Span 2 columns
          gridRow: "span 1",    // Span 1 row
        };
      }
    }
  
    // Default for "standard"
    return {
      gridColumn: "span 1", // Span 1 column
      gridRow: "span 1",    // Span 1 row
    };
  };
  
