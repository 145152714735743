import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";
// import NavBarUserLogin from "pages/home/header/NavBarLoginComponents/Navigation/NavBarUserLogin"
import { useNavigate, Link } from 'react-router-dom';



//EXPANDED INTERACTIVE SEARCHBAR

import NavBarLoginSM from "apps/BusinessAppComponents/NavBarComponents/NavBarLoginSM";// import { AppNavBarManagerProvider } from "context/AppNavBarContext"

// import {useNavBarBusinessAppContext} from "context/NavBarBusinessAppContext";
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useBusinessAppNavBarContext } from "../Context/BusinessAppNavBarContext";

import ProfileMenuButton from "apps/BusinessAppComponents/Menus/ProfileMenuButton";


function NavBarLogin() {

     //navigation
     const navigate = useNavigate();

     //useContext

    // const { toggleCartMenu, isInteractiveSearchBarVisible } = useNavBarBusinessAppContext()
    const {isMobile, isMedium, isLarge} = useScreenContext()
    const {toggleProfileMenu, closeProfileMenu} = useBusinessAppNavBarContext()
    const navigateHome = () => {
        navigate('/Business/app');
      }

    //   const handleProfileMenuButtonPressed = () => {
    //     toggleProfileMenu()
    //     console.log('menu getting toggled baby')
    //   }

    return (
            <Nav $isMedium={isMedium} $isLarge={isLarge}>

                    {/* NAVBAR SMALL FOR MOBILE*/}
                    {(isMobile) && 
                        <NormalNavBar>
                            <NavBarLoginSM/>
                        </NormalNavBar>

                    }

                    {/* NAVBAR FOR NORMAL VIEWING*/}
                    {(isMedium || isLarge) && (
                        <>
                        <NormalNavBar>
                            {/* LEFT */}
                            <ContainerLeft $isMedium={isMedium} $isLarge={isLarge}>
                                <LogoContainer onClick={navigateHome}>
                                {isLarge &&
                                    <LogoIMG src='assets/cta-logo-one.svg' alt="GoghNow" />
                                }
                                {isMedium &&
                                    <LogoIMG src='assets/cta-logo-oneSM.svg' alt="GoghNow" />
                                }
                                </LogoContainer>
                            </ContainerLeft>

                        {/* MIDDLE */}
                            <ContainerCenter>

                            </ContainerCenter>

                        {/* RIGHT */}
                            <ContainerRight $isMedium={isMedium} $isLarge={isLarge}>
                                <ProfileMenuButton/>
                            </ContainerRight>
                        </NormalNavBar>
                        
                        {/* Search Bar expands to this */}
                    </>
                    )}

            </Nav>        
    )
}
export default NavBarLogin;
//CONTAINER
const Nav = styled.nav`
display: flex;
background-color: white;
color: black;
justify-content: space-between;
align-items: center;
// padding: 10px 36px;
padding: ${props => {
    if (props.$isMedium) return '10px 15px';
    if (props.$isLarge) return '10px 36px';
    return '10px'; // Default to large if none match
    }};
z-index: 100;
gap: 10px;
flex-direction: column;
width: 100vw;
// margin: 10px 0px;
overflow: visible;
border-bottom: solid 1px #CCCCCC20;
box-shadow: 0 4px 10px -2px #CCCCCC; /* Shadow at the bottom only */
`

//NAVBAR MAIN BAR
const NormalNavBar = styled.div`
min-height: ${NAVBAR_HEIGHT};
background-color: white;
color: black;
display: flex;
justify-content: space-between;
align-items: center;
z-index: 100;
flex-direction: row;
width: 100%;
// flex-grow:1;
// gap: 10px;
box-sizing: border-box;

// padding: 0px 15px;
`

    const ContainerLeft = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: ${props => {
        if (props.$isMedium) return '0px';
        if (props.$isLarge) return '25%';
        return '25%'; // Default to large if none match
      }};
    // padding: 0px 15px;
    align-items: center;
    justify-content: start;

    `

    const LogoIMG = styled.img`
                       
        display: block;
        max-height: 100%;
        max-width: 150px;
        width: auto; /* Ensures image keeps its aspect ratio */
        height: auto;
        object-fit: contain; /* Ensures the image fits within the container without stretching */
        max-height: 50px;
        
    `
        const LogoContainer = styled.div`
        display: flex;
        flex-shrink: 1;
        background-color: transparent;
        width: auto;
        height: 50px;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        `

    const ContainerCenter = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 50%;
    padding: 0px 15px;
    align-items: center;
    justify-content: center;

    `

    const ContainerRight = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: ${props => {
    if (props.$isMedium) return '0px';
    if (props.$isLarge) return '25%';
    return 'auto'; // Default to large if none match
    }};
    // padding: 0px 15px;
    align-items: center;
    justify-content: end;

    `
