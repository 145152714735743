import React, {useEffect, useRef, useState } from 'react';

//screen useContext
import { useScreenContext } from 'context/AppContext/ScreenContext';

//styled components
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';


const BusinessProfileMenu = ({...props}) => {
    const {isMobile, isMedium, isLarge} = useScreenContext()

    const handleMenuItemClick = (itemName) => {
        console.log(`Selected: ${itemName}`);
      };

    return (
        <>
            {(isMedium || isLarge) && (
                
                <MenuContainer {...props}>
                    <MenuList>
                        <MenuItem onClick={() => handleMenuItemClick("Profile")}>
                            <MenuItemIconContainer>
                                <MenuItemIconIMG src='/images/cta-logo-oneSM.svg' alt="GoghNow" />
                            </MenuItemIconContainer>
                            <MenuItemTextContainer>Profile</MenuItemTextContainer>
                        </MenuItem>

                        <MenuItem onClick={() => handleMenuItemClick("Settings")}>                    
                            <MenuItemIconContainer>
                                <MenuItemIconIMG src='/images/cta-logo-oneSM.svg' alt="GoghNow" />
                            </MenuItemIconContainer>
                            <MenuItemTextContainer>Setting</MenuItemTextContainer>
                        </MenuItem>

                        <MenuItem onClick={() => handleMenuItemClick("Logout")}>
                            <MenuItemIconContainer>
                                <MenuItemIconIMG src='/images/cta-logo-oneSM.svg' alt="GoghNow" />
                            </MenuItemIconContainer>
                            <MenuItemTextContainer>Logout</MenuItemTextContainer>
                        </MenuItem>
                        
                    </MenuList>
                </MenuContainer>
            
            )}

            {(isMobile) && 
                <smMenuContainer {...props}>
                    <p>nothing burger</p>
                </smMenuContainer>
            }
        </>
    )

}

export default BusinessProfileMenu



const MenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 5px); // Position it right under the button
  right: 0; // Align it to the right of the button
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  z-index: 9999;
  width: 250px; // Adjust width as needed
  max-height: 300px; // Optional: limit height
  overflow: hidden; // Hide overflow if needed

`;

const smMenuContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%:
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    z-index: 1000;
`;



const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
display:flex;
align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 50px;
border-radius: 25px;


  &:hover {
    background-color: #f0f0f0; /* Highlight color */
    border-radius: 25px;
  }

  &:active {
    background-color: #e0e0e0; /* Active state color */
    border-radius: 25px;
  }
`;

const MenuItemTextContainer = styled.div`
display: flex;
align-items: center;
justify-content: start;
width: auto;
height: 50px;
flex-grow: 1;
`

const MenuItemIconContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 40px;
height: 40px;
flex-grow: 0;
margin-right:5px;
border-radius: 40px;
background-color: #EEE;
padding: 5px;

`

const MenuItemIconIMG = styled.img`
                       
display: block;
max-height: 25px;
max-width: 25px;
width: auto; /* Ensures image keeps its aspect ratio */
height: auto;
object-fit: contain; /* Ensures the image fits within the container without stretching */
max-height: 50px;

`
