import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";
import { useNavigate, Link } from 'react-router-dom';
import React, { forwardRef } from 'react';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//logo
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one-wht.svg';


const NavBarLogout = ({handleNavigateHome, ...props}) => {

    const navigate = useNavigate();

    const navigateHome = () => {
        console.log("YO")
        navigate('/');
        handleNavigateHome();
      }

    const { isMenuOpen, toggleMenu, updateMenuItems } = useGeneralSignUpMenuContext()

    const handleJoinNowClick = () => {
        // Example menu items
        const joinNowMenuItems = [
            {
            title: "Create a user account →",
            description: 'coming soon',
            link: null,
            enabled: false
            // link: "/Signup",
            },
            {
            title: "Sign up to Provide Services →",
            description: "coming soon",
            link: null,
            enabled: false
            },
            {
            title: "Sign up for GoghNow Business →",
            description: "coming soon",
            link: null,
            enabled: false
            }
        ];
        updateMenuItems(joinNowMenuItems); // Set the menu items
        toggleMenu('join'); // Open the menu
      };

      const handleLoginNowClick = () => {
        // Example menu items
        const loginNowMenuItems = [
            {
            title: "Sign in to create experiences →",
            description: 'coming soon',
            link: null,
            // link: "/Login",
            enabled: false
            },
            {
            title: "Sign in to Provide Services →",
            description: "coming soon",
            link: null,
            enabled: false
            },
            {
            title: "Sign in for GoghNow Business →",
            description: "coming soon",
            link: null,
            enabled: false
            }
        ];
        updateMenuItems(loginNowMenuItems); // Set the menu items
        toggleMenu('login'); // Open the menu
      };
    
    return (
        <Nav {...props}>
            <LogoContainer onClick={navigateHome}>
                <LogoIMG alt="GoghNow Logo"/>
                {/* <img src='/images/cta-logo-one-wht.svg' alt="GoghNow" /> */}
            </LogoContainer>
            <NavMenu>
                <NavElementJoinNow onClick={handleJoinNowClick}>
                    {/* <IconContainer>
                        <img src="/images/home-icon.svg" alt="HOME" />
                    </IconContainer> */}
                    <p>JOIN NOW</p>
                </NavElementJoinNow>
                <NavElementLogin onClick={handleLoginNowClick}>
                    {/* <IconContainer>
                        <img src="/images/search-icon.svg" alt="SignIn" />
                    </IconContainer> */}
                    <p>Sign In</p>
                </NavElementLogin>
            </NavMenu>
            {/* <LoginButton>

            </LoginButton> */}
        </Nav>
    )
};

const Nav = styled.nav`
// position: fixed;
top : 0;
left: 0;
right: 0; 
height: ${NAVBAR_HEIGHT};
background-color: white;
color: black;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 36px;
z-index: 100;
width: 100dvw;
`

const LogoContainer = styled.a`
   display: flex;
flex-shrink: 1;
background-color: transparent;
width: auto;
height: 50px;
max-width: 100%;
max-height: 100%;
cursor: pointer;
// padding: 0px;
// width: 150px;
// margin-top: 4px;
// max-height: 70px;
// font-size: 0px;
// display: inline-block;
// cursor: pointer;

`

const LogoIMG = styled(GoghNowLogo)`
                       
display: block;
max-height: 100%;
max-width: 150px;
width: auto; /* Ensures image keeps its aspect ratio */
height: auto;
object-fit: contain; /* Ensures the image fits within the container without stretching */
max-height: 50px;

`

const NavMenu = styled.div`

align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;
margin: 0px;
padding: 0px;
position: relative;
gap: 15px;
// margin-right: auto;
// margin-left: 25px;

@media (max-width: 768px){
    display: none;
}
`

const NavElementLogin = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 2px solid #FBAE62;
color: #FBAE62;
cursor: pointer;
background-color: transparent;

&:hover{
    background-color: #FBAE6260;
}

`

const NavElementJoinNow = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 1px solid transparent;
background-color: #EEE;
color: black;
cursor: pointer;

&:hover{
    background-color: #CCCCCC70;
    color: white;
}
`

const IconContainer = styled.a`
padding: 0px;
width: 50px;
margin-top: 4px;
max-height: 50px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
export default NavBarLogout;