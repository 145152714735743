// import React from 'react';
import { css } from '@emotion/react';


//Hides mobile styled
export const mobileSizeDisplayNone = css`
@media (max-width: 769px) {
    display: none;
    // background-color: red;
}
`

export const mobileSizeDisplayFlex = css`
@media (max-width: 769px) {
    display: flex;
    // background-color: green;

}
@media (min-width: 769px) {
    display: none !important;
    // background-color: orange;
}
`

export const mobileSizeDisplayBlock = css`
@media (max-width: 769px) {
    display: block;
}`
export const mobileDisplayContentVertical = css`
  @media (max-width: 769px) {
    flex-direction: column;
}
`

export const mobileDisplayContentHorizontal = css`
  @media (max-width: 769px) {
    flex-direction: row;
}
`;

export const mediumDisplayContentHorizontal = css`
  @media(max-width: 1024px) {
  
  flex-direction:row;
  }

`

const mobile = css`
  @media (max-width: 769px) {
    display: none;
  }
`;


//medium styles
export const medium = css`
  @media (min-width: 769px) and (max-width: 1024px) {
    background-color: lightyellow;
    padding: 15px;
  }
`;


//large styles
export const large = css`
  @media (min-width: 1025px) {
    background-color: lightgreen;
    padding: 20px;
  }
`;


//Examples
export const baseContainerStyle = css`
  width: 100%;
  padding: 20px;
`;

export const mediaMaxWidth768 = css`
  @media (max-width: 768px) {
    background-color: lightpink;
    padding: 10px;
  }
`;

export const mediaMinWidth769MaxWidth1024 = css`
  @media (min-width: 769px) and (max-width: 1024px) {
    background-color: lightyellow;
    padding: 15px;
  }
`;

export const mediaMinWidth1025 = css`
  @media (min-width: 1025px) {
    background-color: lightgreen;
    padding: 20px;
  }
`;
