import React, { createContext, useState, useContext, useEffect } from 'react';
// import { useCart } from './CartContext';
// import { useAuthContext } from './AppContext/AuthContext';
// import GoogleMapsFunctions from 'utility/googleMapsFunctions';

const AppNavBarContext = createContext();

export const useBusinessAppNavBarContext = () => useContext(AppNavBarContext);
// export const WidgetManagerContext = createContext();


export const BusinessAppNavBarManagerProvider = ({ children}) => {

    // const { resetAllContexts} = useAuthContext();
   
    //NEED
    const [isInteractiveSearchBarVisible, setIsInteractiveSearchBarVisible] = useState(true);
    


     //Reset Context
    const resetContext = () => {
        closeProfileMenu();
    }


    // useEffect(() => {
    // if (resetAllContexts) {
    //     resetContext();
    // }
    // }, [resetAllContexts]);





    //CART MENU STATES
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    
    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(prevState => !prevState);
        console.log('isProfileMenuOpen', isProfileMenuOpen);
    };

    const closeProfileMenu = () => setIsProfileMenuOpen(false);


    return (
        <AppNavBarContext.Provider
            value={{ 
                isProfileMenuOpen,setIsProfileMenuOpen,
                toggleProfileMenu, closeProfileMenu


             }}
        >
            {children}
        </AppNavBarContext.Provider>
    );
};