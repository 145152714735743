//React
import React, {useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//HomeURLProvider
import { HomeURLProvider } from "context/URL/HomeURLContext";


//Common Components
// import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"

//Redux
import { useCartActions } from "third_party/redux/cart/cartActions";
import { useCart } from "third_party/redux/selectors";

import {useSearch } from "third_party/redux/selectors";
import { useSearchActions } from "third_party/redux/search/searchActions";

import { useSearchFilter } from "third_party/redux/selectors";
import { useSearchFilterActions } from "third_party/redux/searchFilter/searchFilterActions";

import {useAppNavBarContext} from "context/AppNavBarContext";
import { useUserServiceContext } from "api/User/UserService/UserServiceAPIContext";

// import {keyframes} from "styled-components"; //CHANGE TO EMOTION
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

import IconNavBarComponent from "pages/home/components/NavBarIconComponents/IconNavBarComponent"
import TrendyPackageComponent from "./TrendingPackagesComponents/TrendyPackageComponent";
import EventServicesComponent from "./EventServicesComponents/EventServicesComponent";
// import { NAVBAR_HEIGHT } from "components/constants";
//CART CONTEXT
import CartMenu from "components/Cart/CartMenu"

//JUST ADDED 
// import NavBarLogin from "../../pages/home/header/NavBarLogin"
// import SearchInputContainer from "pages/home/header/PopUpInteractiveComponents/SearchInputContainer";

//INPUT COMPONENTS
import DateTimeInput from "../SearchComponents/DateTimeSearchComponent/DateTimeInput";
import DurationInput from "../SearchComponents/DurationSearchComponent/DurationInput";
import LocationInput from "../SearchComponents/LocationSearchComponent/LocationInput";


import CartButton from "components/Cart/CartButton"
import { NAVBAR_HEIGHT } from "components/constants";
import { useScreenContext } from 'context/AppContext/ScreenContext';
import MobileSearchContainer from 'apps/UserAppComponents/Mobile/MobileSearchContainer';
import Overlay from "components/Containers/Overlay";

import InternalServiceAPI from "api/User/InternalService/InternalServiceAPI";



// import { useDispatch } from 'react-redux';
// import { deleteCartItem } from 'third_party/redux/cart/cartActions';

import SearchFunctions from "utility/searchFunctions";



const UserAppContent = (props) => {


    //navigate
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true); // Track if it's the first load

    //Search Context For Items
    const {SEARCH_UPDATE_TEMP, SEARCH_LOCATION, SEARCH_RESULTS_UPDATE} = useSearchActions()
    const searchCriteria = useSearch().current
    const all_available_services = useSearch().results
    const [filteredServices, setFilteredServices] = useState([])

    //Search Filter
    const {FILTER_RESET} = useSearchFilterActions()
    const searchFilters = useSearchFilter()

    const pageContentRef = useRef(null);  // Ref for PageContentContainer
    const containerRef = useRef(null);
    const handleClickOutside = (event) => {
        console.log("HERE!!!")
        if (containerRef.current && containerRef.current === event.target) {
            // closeSearchInputsViews();
            closeExpandedSearchEditMode()
            console.log('Container closed (click outside)');
          } else {
            console.log('Container open (click inside)');
          }
      };
      const setLocation = (place) => {
        SEARCH_LOCATION(place)
        closeSearchInputsViews()
      }

    //CART AND INTERACTIVE JOINT
    const {isCartMenuOpen,
        closeCartMenu,
        toggleCartMenu,
        //BELOW IS SearchInputContainer Items
        isInteractiveSearchBarVisible,
        closeSearchInputsViews,
        isLocationSearchVisible,
        isWhenSearchVisible,
        isDurationSearchVisible, 
        //scroll Joint
        openExpandedSearch,
        closeExpandedSearchEditMode,
        setCanToggleBasedOnScroll,
        canToggleBasedOnScroll,
        scrollToOpenInteractiveSearch,
        scrollToCondenseInteractiveSearch,
        showOverlay,
        isAnySearchInputVisible

    } = useAppNavBarContext()

        //car
        const cartItems = useCart()
        const {CART_DELETE} = useCartActions()
        //screen
    const {isMobile, isMedium, isLarge} = useScreenContext()

    useEffect(() => {
        const pageContentElement = pageContentRef.current;
        
        const handleScroll = (event) => {

            const scrollPosition = pageContentElement.scrollTop;

            if(canToggleBasedOnScroll && !isMobile){
                if (event.isTrusted) {
                    // Only handle user-initiated scrolls
                    
                    if (scrollPosition < 1) {
                        scrollToOpenInteractiveSearch();
                    } else {
                        scrollToCondenseInteractiveSearch();
                    }
                }
            }else {
            }
        };
      
        if (pageContentElement) {
          pageContentElement.addEventListener('scroll', handleScroll);
          
          return () => {
            pageContentElement.removeEventListener('scroll', handleScroll);
          };
        }
      }, [canToggleBasedOnScroll, isMobile, isMedium, isLarge]);
    


    const goToCheckout = () => {
        console.group('Checkout Details');
        console.log('Cart Items:', cartItems);
        console.log('Search Criteria:', searchCriteria);
        console.groupEnd();
      

        navigate('/book', {state: {searchCriteria, cartItems}});
      }


    const internalApiService = new InternalServiceAPI();

      const [servicesAreLoading, setServicesAreLoading] = useState(true)
      // const [availableServices, setAvailableServices] = useState([])

      //=============================================================
      //USE EFFECT IS HOW YOU LOAD THINGS ON INITIAL SCREEN LOAD!
      //BREAK LOADING OUT INTO FUNCTIONS AND THAT why you can do page
      //REFRESH WITH CERTAIN THINGS... IE FILTERS, ETC
      useEffect(() => {
        const loadInitialServices = async () => {
          setServicesAreLoading(true);
          // Simulate a 2-second delay
          setTimeout(async () => {
            try {
              const updated_services = await internalApiService.getEventServices(); // Fetch data asynchronously
              // setAvailableServices(services);
              console.log('UPDATED SEARCH SERVICES: ',updated_services)
              SEARCH_RESULTS_UPDATE(updated_services);
              setFilteredServices(updated_services,searchFilters)
              setServicesAreLoading(false); // Stop loading after data is fetched
              setInitialLoad(false)
            } catch (error) {
              console.error('Error fetching services:', error);
              // setAvailableServices([]);
              SEARCH_RESULTS_UPDATE([]);
              setFilteredServices([],searchFilters)
              setServicesAreLoading(false); // Stop loading after data is fetched
              setInitialLoad(false)
            }
          }, 1000); // 2-second delay
        };
        loadInitialServices()
      }, []);

      //NEED A USE EFFECT LIKE, SEARCH SHOULD UPDATE, and we should grab search, not in this component though
      const handleDeleteItemFromCart = (item) => {
        // dispatch(deleteCartItem(item));
        CART_DELETE(item)
    };

    //USE EFFECT THAT UPDATES THE SEARCH
    //==================================
    useEffect(()=> {

      if (servicesAreLoading!==true && initialLoad===false) {
        console.log("NEED TO UPDATE JOINTS")
          setServicesAreLoading(true)
        setTimeout(async () => {
          try {
            const updated_services = await internalApiService.getEventServices(); // Fetch data asynchronously
            // setAvailableServices(services);
            // FILTER_RESET() //RESETING FILTERS
            SEARCH_RESULTS_UPDATE(updated_services);
            setFilteredServices(SearchFunctions.filterServices(updated_services,searchFilters))

            setServicesAreLoading(false); // Stop loading after data is fetched

          } catch (error) {
            console.error('Error fetching services:', error);
            // setAvailableServices([]);
            SEARCH_RESULTS_UPDATE([]);
            setServicesAreLoading(false); // Stop loading after data is fetched
          }
        },1000)
        }

    }, [searchCriteria]);

    //FILTER UPDATES
    //==================================
    useEffect(()=>{
      if (servicesAreLoading!==true && initialLoad===false) {

        console.log("THIS SHOULDN'T BE TRIGGERED ")
        setServicesAreLoading(true)
        setTimeout(async () => {
          //DO UPDATING STUFF HERE
          const services_filtered= SearchFunctions.filterServices(all_available_services,searchFilters)

          setFilteredServices(services_filtered)
          console.log("SEARCH FILTERS ARE THIS:",services_filtered)
          setServicesAreLoading(false)

          },100
        )
      }

    },[searchFilters])


    console.log("isAnySearchInputVisible: ", isAnySearchInputVisible)
    return (
    <>
        {/* <PageContainer className="page-container"> */}

                {/* <NavBarLogin/> */}
                <SpecialPageContentContainer ref={pageContentRef}>


                    {(isMedium || isLarge) && 
                        // <SearchInputContainer/>
                        <>
                            <CloseableOverlay $isOpen={isAnySearchInputVisible} onClick={handleClickOutside} ref={containerRef}>
                                <SearchInputContainer>
                                    <CustomLocationInput isOpen={isLocationSearchVisible} setLocation={setLocation}/>
                                    <CustomDateTimeInput isOpen={isWhenSearchVisible} />
                                    <CustomDurationInput isOpen={isDurationSearchVisible}/>
                                </SearchInputContainer>
                            </CloseableOverlay>
                        </>
                    }

                        <IconNavBarComponent/>
                        {/* <TrendyPackageComponent/> */}
                        <EventServicesComponent services={filteredServices} isLoading={servicesAreLoading}/>
                    
                    {(isMobile) && 
                        <CartContainer>
                            <CartButton onClick={toggleCartMenu} />
                        </CartContainer>
                    }

                </SpecialPageContentContainer>
                
                

                <CartMenu isOpen={isCartMenuOpen} onClose={closeCartMenu}
                    cartItems={cartItems} removeFromCart={handleDeleteItemFromCart} handleCheckOut={goToCheckout} canToggle={true}/>

                {(isMobile) && 
                    <MobileSearchContainer/>
                }
        {/* </PageContainer> */}
    </>
    );
};

//contains the flex and the content which should equal total height of page
// const PageContainer = styled.div`
// dislpay: flex;
// flex-direction: column;
// height: 100vh;
// `

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
`

const CartContainer = styled.div`
position: fixed; 
display: flex;
bottom: 10px;/*calc(${NAVBAR_HEIGHT}/4);*/
left: 50%;
transform: translateX(-50%);
`


//SEARCH INPUT CONTAINER ITEMS
// const SearchInputHolder = styled.div`
// display: flex;
// position: sticky;
// top: 0px;
// left: 0px;
// height: 100%;
// width: 100%;
// // background-color: black;
// align-items: center;
// z-index: 1000;
// `
//isOverlayVisible
const CloseableOverlay = styled.div`
display: flex;
position: fixed;
width: 100%;
height: 100%;
align-items: start;
z-index: 999;
// cursor: ;
background-color: red;
  ${({ $isOpen }) => `
    background-color: ${$isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent'} !important;
    opacity: ${$isOpen ? 1 : 0};
    display: ${$isOpen ? 'block' : 'none'} !important;
    position: ${$isOpen ? 'fixed' : 'relative'} !important;
    transition: opacity 0.3s ease;
  `}

`
const SearchInputContainer = styled.div`
display:flex;
position: absolute;
top: 0px;
left: 10%;
height: auto;
max-height:100%;
width: 80%; 
margin-left: auto;
margin-right: auto;
background-color: transparent;
align-items: start;
justify-content: center;
z-index: 1000;
overflow-y:scroll;

`

const CustomLocationInput = styled(LocationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: scroll;

`

const CustomDurationInput = styled(DurationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15) !important;
height: 50vh;
overflow-y: scroll;
`


const CustomDateTimeInput = styled(DateTimeInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: scroll;
`

const SearchInputOverlay = styled.div`
display:flex;
position: absolute;
top: 0px;
left: 0px;
height: 100%;
width: 100%; 
background-color: #00000080;
align-items: start;
justify-content: center;

${(props) =>
    props.$isOpen
      ? css`
          animation: ${fadeIn} 0.5s forwards;
          display:flex;
        `
      : css`
          animation: ${fadeOut} 0.3s forwards;
          animation-delay: 0s; /* No delay for fade out */
          display: none;
          opacity:0;
        `}
`
// // KEYFRAMES
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;
// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// `;


// Define fade in and fade out animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;



export default UserAppContent;