import React from "react";
import {useState, useEffect, useRef } from "react";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//mobile styled
import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex, mediumDisplayContentHorizontal} from 'components/emotionStyles'

import { useScreenContext } from 'context/AppContext/ScreenContext';


export const BlogPreview = ({ heading, subheading, date, type,content, tag, image, children }) => {
    
    
    const [isHovered, setIsHovered] = useState(false);
    const [isCardHovered, setIsCardHovered] = useState(false);

    const {isMobile, isMedium, isLarge} = useScreenContext()
    console.log('BlogPreview Image | ',image)
    if (type === 'header') {
        return (
          <HeaderCardContainer 
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          $isHovered={isHovered}
          >
            <HeaderImageContainer>
            <CardImageOverlay $isHovered={isHovered}></CardImageOverlay>

              <HeaderImage
                // src="https://via.placeholder.com/1200x800"
                $isHovered={isHovered}
                src={image}
                alt="Header image"
              />
            </HeaderImageContainer>

            <HeaderContent $isHovered={isHovered}>
                <DateText>{formatDate(date)}</DateText>
                <Title>{heading}</Title>
                {isLarge && (
                    <PreviewText>{generatePreview(content)}</PreviewText>
                )}
                <ReadingText><TagText>{tag}</TagText> • {calculateReadingTime(content)}</ReadingText>
                <Children>{children}</Children>
            </HeaderContent>
          </HeaderCardContainer>
        );
      }

  return (
    <CardContainer
    onMouseEnter={() => setIsCardHovered(true)}
    onMouseLeave={() => setIsCardHovered(false)}
    $isCardHovered={isCardHovered}>
        {/* <CardContainerInner> */}
            <CardImageContainerContent>
                <CardImageContainer>
                    <CardImage
                        // src='https://via.placeholder.com/800x600'
                        src={image}
                        alt='image'
                    />
                </CardImageContainer>
            </CardImageContainerContent>
            <CardContainerContent>
                <DateText>{formatDate(date)}</DateText>
                <Title>{heading}</Title>
                {/* <SubTitle>{subheading}</SubTitle> */}
                {isLarge && (
                    <PreviewText>{generatePreview(content)}</PreviewText>
                )}
                <ReadingText><TagText>{tag}</TagText> • {calculateReadingTime(content)}</ReadingText>
                <Children>{children}</Children>
            </CardContainerContent>
         {/* </CardContainerInner> */}
    </CardContainer>
  );
};


const CardContainer = styled.div`
  ${mediumDisplayContentHorizontal};
  color: black;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: white;
  position: relative; /* Required for proper layering */
  transition: box-shadow 0.3s ease; /* Smooth transition effect */
  box-shadow: ${props => {
    if (props.$isCardHovered) return ' 5px 5px 10px #00000060';
    return ''; // Default to large if none match
  }};
`;


const CardContainerInner = styled.div`
${mobileDisplayContentVertical};
color: black;
display: flex;
width:100%;
height: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
border: 2px solid #DDD;
padding: 25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`
const CardImageContainer = styled.div`
margin-bottom: 0px;
width: 100%;
height:auto;
aspect-ratio: 1 / 1;
font-size: 0px;
display: flex;
overflow: hidden;


@media (max-width: 1024px) {
    // flex-grow: 1;
    // width: 50%;
}

  &:hover img {
    transform: scale(1.15);
  }
`

const CardImage = styled.img`

  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover; // Ensures the image fills the space without distorting
  object-position: center; // Ensures the image is centered within its container
  // border-radius: 8px;
  overflow: hidden;
  background-color: #00000020;
    transition: transform 0.2s ease-in-out;


  // Dynamically apply grid column and row values
  // grid-column: ${(props) => props.gridColumn || 'span 1'};
  // grid-row: ${(props) => props.gridRow || 'span 1'};
`

const CardContainerContent = styled.div`
display: flex;
justify-content: start;
align-items: start;
flex-direction: column;
padding: 20px;
gap: 10px;
p{
    margin-top: 0px;
}

@media (max-width: 1024px) {
    flex-grow: 1; 
    flex-shrink:1;  
    padding:10px 10px; 
    
}

`

const CardImageContainerContent = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
height: 100%;
width: 100%;

@media (max-width: 1024px) {
    flex-grow: 0; 
    flex-shrink:2;
    min-width: 25vw;
    max-width: 33vw;
}
`

const Title = styled.p`
font-size: 2em;
font-weight: 600;
margin: 5px 0px;
color: black;
line-height: 90%;

`
const SubTitle = styled.p`
font-size: 1.5em;
font-weight: 800;
margin: 0px;
color: #00000080;

`

const DateText = styled.p`
font-size: 1em;
font-weight: 400;
margin: 0px;
color: gray;
`



// const styles = {
//   blogCard: {
//     border: "1px solid #ddd",
//     padding: "16px",
//     borderRadius: "8px",
//     background: "#f9f9f9",
//     margin: "16px",
//   },
//   date: {
//     fontSize: "0.9rem",
//     color: "#777",
//   },
// };



//=====================
//HEADER
//=====================
const HeaderCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #EEE;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-content: end;
  position: relative; /* Ensures proper layering */
//   border: 1px solid transparent; /* Transparent border to avoid layout shifts */
  min-height: 40vh;
  transition: box-shadow 0.3s ease; /* Smooth transition effect */
  box-shadow: ${props => {
    if (props.$isHovered) return ' 5px 5px 10px #00000060';
    return ''; // Default to large if none match
  }};

    @media (max-width: 1024px) {
        min-height: 50vh;
    }
`;


const HeaderImageContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index:1;
  background-color: black;

//     &:hover img {
//     transform: scale(1.15);
//   }
`;

const HeaderImage = styled.img`
 width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover; // Ensures the image fills the space without distorting
  object-position: center; // Ensures the image is centered within its container
  // border-radius: 8px;
  overflow: hidden;
  background-color: #00000020;
  transition: transform 0.3s ease-in-out; /* Smooth scaling transition */
  transform: ${props => {
    if (props.$isHovered) return 'scale(1.05)';
    return 'scale(1)'; // Default to large if none match
  }};
`;
const CardImageOverlay = styled.div`
display: flex;
position: absolute;
width: 100%;
height: 100%;
opacity: ${props => {
    if (props.$isHovered) return '0.25';
    return '0.75'; // Default to large if none match
  }};
background-color: #FFFFFF;
z-index: 2;
`

const HeaderContent = styled.div`
display: flex;
flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: ${props => {
    if (props.$isHovered) return '#FFFFFF60';
    return 'transparent'; // Default to large if none match
  }};
  z-index: 1;
width: calc(100% - 40px);
gap: 10px;

p{
    margin-top: 0px;
}

`;

const HeaderDate = styled.div`
  font-size: 14px;
  color: gray;
`;

const HeaderTitle = styled.h1`
  font-size: 24px;
  margin: 0px;
  color: black;
`;

const HeaderSubtitle = styled.h2`
  font-size: 18px;
  color: #666;
  margin-bottom: 0px;
`;

const PreviewText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const Children = styled.div`

`
const ReadingText = styled.p`
margin:0px;
color: black;
`

const TagText = styled.span`
  color: orange; /* Make "FRIEND" orange */
  font-weight: bold; /* Optional: emphasize "FRIEND" */
//   margin-right: 5px; /* Add some space before the dot */
`;


const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long', // e.g., "Monday"
      month: 'long',   // e.g., "January"
      day: 'numeric',  // e.g., "1"
      year: 'numeric', // e.g., "2024"
    });
  };

  const calculateReadingTime = (content = []) => {
    // Combine all text into a single string
    const text = content
      .map((block) => {
        if (block.type === "p") return block.plainText; // Get paragraph text
        if (block.type === "list") return block.plainItems.join(" "); // Combine list items into a single string
        return ""; // For other types (e.g., headings), we don't consider them for reading time
      })
      .join(" "); // Combine all content with a space separator
  
    // Count words (split by spaces and filter out empty strings)
    const wordCount = text.split(/\s+/).filter((word) => word.length > 0).length;
    // Calculate reading time (assuming 200 words per minute)
    const readingTime = Math.ceil(wordCount / 200);
    return `${readingTime} min read`;
  };

  const generatePreview = (content) => {
    // Find the first paragraph (p) block
    const paragraph = content.find((block) => block.type === "p");
    // Return the first 100 characters of the paragraph text, or a default message
    return paragraph ? paragraph.plainText.slice(0, 100) + "..." : "No preview available.";
  };