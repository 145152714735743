import React, { createContext, useState, useContext, useEffect } from 'react';
import { NAVBAR_HEIGHT } from "components/constants";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import { keyframes } from '@emotion/react'

//components
import { useScreenContext } from 'context/AppContext/ScreenContext';

// import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"
import {GoogleMapsAddressInput} from "components/Inputs/GoogleMapsAddressInput"

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { ActionButton } from 'components/standard';

import SearchFunctions from 'utility/searchFunctions';

const BusinessSignup = ({onSubmit,submitting, data = {}, formRef}) => {
//name = "Signup", description ="Sigup today!"
    const {isMobile, isMedium, isLarge} = useScreenContext()

    const [error, setError] = useState(false); //THIS SHOULD BE HANDLED OUTSIDE
    const [user, setUser] = useState(data)
    // const [submitting, setSubmitting] = useState(false);
    const [place, setPlace] = useState({})
    // useEffect(() => {
    //     if (isOpen) {
    //       // document.body.style.overflow = 'hidden';
    //     } else {
    //       // document.body.style.overflow = 'auto';
    //       setUser({data});
    //     }
    
    //     // Cleanup function to remove the style when the component unmounts
    //     return () => {
    //       document.body.style.overflow = 'auto';
    //     };
    //   }, [isOpen]);

    //   useEffect(() => {
    //     // Initialize user with data if available when component mounts or data changes
    //     if (data) {
    //         setUser(prevState => ({
    //             ...prevState,
    //             ...data
    //         }));
    //     }
    // }, [data]);


      const handleInputChange = (e) => {
        const { id, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [id]: value
        }));

        console.log('CHANGE: ',user)
    };


      const handleSearchSubmit = (address) => {

        //maybe use a try and if error, means no location came back, display that
        console.log('address:', address);
        // loginAsGuestWithAddress(address)
      };
    
      // Function to handle place selection
      const handlePlaceSelected = (place) => {
        console.log('Place selected:', place);
        setUser(prevState => ({
            ...prevState,
            address: SearchFunctions.getAddressFromPlace(place),
            lat: place.lat,
            lng: place.lng
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(user);
    };

    const handlePanelClick = (event) => {
        event.stopPropagation(); // Prevent propagation
      };

              // UseEffect to reset user data when the form is reset
              useEffect(() => {
                if (formRef.current) {
                  formRef.current.addEventListener('reset', () => {
                    setUser({
                      // name: '',
                      // email: '',
                      // zip: '',
                      // detail: ''
                    });
                  });
                }
      
                // Cleanup the event listener when the component unmounts
                return () => {
                  if (formRef.current) {
                    formRef.current.removeEventListener('reset', () => {
                      setUser({
                        // name: '',
                        // email: '',
                        // zip: '',
                        // detail: ''
                      });
                    });
                  }
                };
              }, []);
      


      return (
        // <CloseOnTapOverlayContainer $isOpen={isOpen} onClick={onClose}>
            <Panel onClick={handlePanelClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <BodyPanel>
                    <FormContainer>
                        <form id="FormID" ref={formRef} onSubmit={handleSubmit}>

                              <SpecialTextField
                                id = "company"
                                label="Company Name"
                                type="text"
                                variant="outlined"
                                value={user.company || ''}
                                onChange={handleInputChange}
                                error={error}
                                helperText={error ? "This field is required" : ""}
                                fullWidth
                                // size="small"
                                // margin="normal"
                                required
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                            />
                            <Box display="flex" marginTop={2} marginBottom={2} gap={0}>
                              <SpecialTextField
                                  id = "firstname"
                                  label="First name"
                                  type="name"
                                  variant="outlined"
                                  value={user.firstname || ''}
                                  onChange={handleInputChange}
                                  error={error}
                                  helperText={error ? "This field is required" : ""}
                                  fullWidth
                                  // size="small"
                                  // margin="normal"
                                  required
                                  // InputLabelProps={{
                                  //     shrink: true,
                                  // }}
                              />
                              <SpecialTextField
                                  id = "lastname"
                                  label="Last name"
                                  type="name"
                                  variant="outlined"
                                  value={user.lastname || ''}
                                  onChange={handleInputChange}
                                  error={error}
                                  helperText={error ? "This field is required" : ""}
                                  fullWidth
                                  // size="small"
                                  // margin="normal"
                                  required
                                  // InputLabelProps={{
                                  //     shrink: true,
                                  // }}
                              />
                            </Box>

                            <SpecialTextField
                            id='email'
                            label="Work email"
                            type="email"
                            variant="outlined"
                            value={user.email || ''}
                            onChange={handleInputChange}
                            error={error}
                            helperText={error ? "Please enter a valid email address" : ""}
                            fullWidth
                            // size="small"
                            // margin="normal"
                            required
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            />

                            <SpecialTextField
                                id="phone"
                                label="Phone number"
                                type="tel"
                                variant="outlined"
                                value={user.phone || ''}
                                onChange={handleInputChange}
                                fullWidth
                                // size="small"
                                margin="normal"
                                required
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                            />
                            <SpecialTextField
                              id="detail"
                              label="How can we help?"
                              type="text"
                              variant="outlined"
                              value={user.detail || ''}
                              onChange={handleInputChange}
                              fullWidth
                              // size="small"
                              margin="normal"
                              multiline
                              required
                              rows={3} // You can adjust the number of rows as needed
                            //   InputLabelProps={{
                            //     shrink: true,
                            // }}
            />

                    <CheckOutButton onClick={handleSubmit} disabled={submitting}>
                      {submitting ? 'Sending...' : `Submit →`}
                    </CheckOutButton> 
                        </form>
                    


                    </FormContainer>
                </BodyPanel>

            </Panel>
        // </CloseOnTapOverlayContainer>
      );

}

export default BusinessSignup;



// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const slideUp = keyframes`
//   from {
//     transform: translateY(100%);
//   }
//   to {
//     transform: translateY(0);
//   }
// `;


const Panel = styled.div`
display: flex;
flex-direction: column;
overflow-y: scroll;
width: 100%;
max-width: 1120px;
gap: 10px;


`

const SpecialTextField = styled(TextField)`
  border-radius: 0px;
  background-color: white;
  border: 4px solid transparent;
  border-radius: 0px !important;
  padding:0px;
`
const HeaderPanel = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
justify-content: end;
align-items: center;
`

const CloseButton = styled.button`
   
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        font-weight: 600;
        font-size: 1.5em;
        background-color: transparent;
        border: 1px solid transparent;
        &:hover{
            background-color: #CCCCCC;
        }
        cursor: pointer;
}`

const BodyPanel = styled.div`
display: flex;
height: auto;
min-height: calc(100% - (2 * ${NAVBAR_HEIGHT}));
width: 100%;
flex-wrap: wrap;
gap: 50px;
flex-grow: 1;
overflow-y: scroll;
`

const FormContainer = styled.div`
display: flex;
flex-direction: column; 
align-items: center;
// padding: 15px;
width: 100%;
// background-color: yellow;
`


//ADDED STUFF
const SubHeading = styled.h2`
color: black;
margin-bottom: 0px;
margin-top: 0px;
`
const SubHeadingDetails = styled.p`
color: #00000060;
margin-bottom: 0px;
margin-top: 0px;
`

const SectionHeadingTitle = styled.h4`
color: #00000055;
`
const Space = styled.div`
display:flex;
width:100%;
height: 1px;
background-color: #000000;
margin: 15px 0px;
`

const SpaceEmpty = styled.div`
display:flex;
width:100%;
height: 1px;
// background-color: #000000;
margin: 7.5px 0px;
`

//ITEMS
// const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
//     background-color: transparent;
//     color: black;
//     font-size: .875em;

//     &.input-please-work {
//         background-color: transparent !important;
//         border: 1px solid #BBB;
//         border-radius: 0px;
//       }
// `

const CheckOutButton = styled.div`
olor: #FFFFFF;
font-weight: 700;
font-size: 22px;
cursor: pointer;
background-color: #FBAE6290;
padding: 15px;

margin-top: 15px;
border-radius: 10px;
text-align: center;
width: 100%;
&:hover{
color: #FFFFFF;
background-color: #FBAE62;
}

`