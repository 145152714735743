import { useEffect, useRef } from 'react';

const useSmoothScroll = (resistance = 0.1) => {
//   const scrollRef = useRef(window.scrollY); // Store current scroll position
//   const isScrolling = useRef(false);        // Track if scrolling is happening

//   useEffect(() => {
//     const smoothScroll = () => {
//       // Calculate the scroll distance and apply resistance
//       const scrollPosition = window.scrollY;
//       const diff = scrollPosition - scrollRef.current;
//       const moveBy = diff * resistance;

//       // Apply a smoother scroll by gradually updating scroll position
//       scrollRef.current += moveBy;
//       window.scrollTo(0, scrollRef.current);

//       // Stop the scroll effect when close enough to the target position
//       if (Math.abs(diff) > 0.5) {
//         isScrolling.current = true;
//         requestAnimationFrame(smoothScroll);
//       } else {
//         isScrolling.current = false;
//       }
//     };

//     const handleScroll = () => {
//       // Trigger only if not already in a scroll animation
//       if (!isScrolling.current) {
//         requestAnimationFrame(smoothScroll);
//       }
//     };

//     // Add and remove event listeners
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [resistance]);

//   return null; // This hook doesn’t render anything directly
};

export default useSmoothScroll;
