import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; /*Switch is now Routes*/
// import Home from "./pages/home/Home"

//User
import Home from 'pages/home/Home';
//User App
import UserApp from 'apps/UserApp';
import Book from "./pages/book/Book"

//Provider
import Provider from "./pages/provider/Provider"

//SignUp/Login
import Signup from "./pages/signup/Signup"
import SignupWithEmail from 'apps/newSignUpComponents/SignUpMaster'; //NEW

import Login from "./pages/login/Login"

//Business
import Business from './pages/business/Business';
import BusinessApp from 'apps/BusinessApp';

//Careers
import BizDevRep from 'pages/careers/biz-dev-rep-I';

//Embedded Test
import Embedded from "./embedded/Embedded"

import {AppProvider} from './context/AppProvider'
 
import './App.css';

import { SkeletonTheme } from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BlogHome from 'pages/blogs/BlogHome';
import BlogPost from 'components/Blogs/BlogPost';

function App() {
  
  return (
    <div className="App">
      <SkeletonTheme baseColor="#CCC" highlightColor='#EEE'>
        <AppProvider>
        {/* <AuthProvider>
          <UserProvider> */}
            <Router>
              <ScrollToTop/>
              <Routes>
                {/* HOME */}
                <Route path="/" element={<Home />} />
                {/* SEARCH */}
                <Route path="/s" element={<UserApp />} />
                {/* PROVIDER */}
                <Route path="/provider" element={<Provider />} />
                {/* SIGNUP */}
                <Route path="/signup" element={<SignupWithEmail />} />
                {/* LOGIN */}
                <Route path="/login" element={<Login />} />
                {/* BOOKING PAGE */}
                <Route path="/book" element ={<Book />} />

                {/* BUSINESS PAGE */}
                <Route path="/business" element ={<Business />} />
                {/* BUSINESS APP */}
                <Route path="/business/app" element ={<BusinessApp />} />

                {/* BLOG STUFF */}
                <Route path="/blog" element={<BlogHome />} />
                {/* <Route path="/Blog/:id" element={<BlogPost />} /> */}
                <Route path="/blog/:heading" element={<BlogPost />} />


                {/* Career Paths */}
                <Route path="/careers/biz-dev-rep-I" element={<BizDevRep />} /> {/* Add this route */}


                {/* EMBEDDED IDK WHAT THIS IS */}
                <Route path="/embedded" element ={<Embedded />} />


              </Routes>
            </Router>
          {/* </UserProvider>
        </AuthProvider> */}
        </AppProvider>
      </SkeletonTheme>
    </div>
  );
}

export default App;


//SCROLL TO TOP WHEN NAVIGATING

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const html = document.documentElement;

    // Store the original scroll behavior to restore later
    const originalScrollBehavior = html.style.scrollBehavior;

    // Temporarily disable smooth scrolling for path change
    html.style.scrollBehavior = 'auto';

    // Jump to the top instantly without smooth scrolling
   //window.scrollTo(0, 0); // or use this
    document.documentElement.scrollTop = 0;

    // Restore the original scroll behavior after path change
    html.style.scrollBehavior = originalScrollBehavior;
  }, [pathname]);
  return null; // No UI element is rendered
};

