//REACT
import React from "react";
import {useState, useEffect, useRef } from "react";
import {useNavigate, useLocation } from 'react-router-dom';

import { useParams } from "react-router-dom";
import { blogs } from "pages/blogs/blogsData";

// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//Helmet
import { Helmet } from 'react-helmet';

//Framer Motion
import { motion, useScroll, useTransform } from 'framer-motion';

//Common Components
import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"
import NavBarLogoutGeneral from "components/navbar/NavBarLogoutGeneral";
import FooterLoggedOut from 'components/Footer/FooterLoggedOut';
import { CALLTOACTION, SECTION } from "components/standard";

import DOMPurify from 'dompurify';
import { NAVBAR_HEIGHT, SECTION_PADDING } from "components/constants";


const BlogPost = (props) => {
  // const { id } = useParams();
  // const blog = blogs.find((b) => b.id === parseInt(id));
  const navigate = useNavigate();

  const { heading } = useParams();
    // Decode the slug back into the original heading
    // const decodedHeading = heading.replace(/-/g, ' '); // Replace hyphens with spaces
    // const blog = blogs.find((b) => b.heading.toLowerCase() === decodedHeading.toLowerCase());

    
  // Decode the heading from the URL
  const decodedHeading = heading.replace(/-/g, ' '); // Convert dashes to spaces

  // Match blogs by normalizing spaces and dashes to a single format
  const blog = blogs.find(
    (b) =>
      b.heading
        .toLowerCase()
        .replace(/[\s-]+/g, ' ') === decodedHeading.toLowerCase()
  );

//contentRef
const contentRef = useRef();

const scrollToTop = () =>{
    console.log("this is working?")
    // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
  } 
// const scrollToTop =() =>{
//     console.log("this is working?")
//     if (contentRef.current) {
//         contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//       }
// } 


    //RETURNS THIS IF THERE IS NOT BLOG FOUND!
    if (!blog) return (
    <p>Blog not found!</p>
    );


    const handleBackPressed = () => {
      navigate(-1); // -1 will navigate to the previous page
    }


  return (
    <>
    <Helmet>
        <title>{blog.heading} | GoghNow Blog</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'${blog.heading} | GoghNow Blog'}/>
        <meta name="description" content="GoghNow is simplifying events and helping you stay ahead of the curve. Explore thought-provoking articles on event strategy, design, technology, and inspiration to bring your vision to life" />
        <meta property="og:image" content="images/cta-logo-one.png" />
    </Helmet>
    <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>

    <SpecialPageContentContainer ref={contentRef}>
    
    <BlogContentSection>
        <BlogHeaderContent>
        <HeaderPanel>
          <button onClick={handleBackPressed}>←</button>
          {/* <p><strong>back to blogs</strong></p> */}
        </HeaderPanel>
          <DateText>{formatDate(blog.date)}</DateText>
          <HeadingText>{blog.heading}</HeadingText>
          <HeadingSubText>{blog.subheading}</HeadingSubText>
          <Author>by {blog.author}</Author>
        </BlogHeaderContent>  
        <HorizontalLine/>
        <BlogContent>
            {renderContent(blog.content)}
        </BlogContent>
    </BlogContentSection>

      <FooterLoggedOut></FooterLoggedOut>
    </SpecialPageContentContainer>
    </>
  )
}


export default BlogPost;

const HeaderPanel = styled.div`
display: flex;
flex-direction:row;
width: 100%;
height: ${NAVBAR_HEIGHT};
// justify-content: start;
// align-items: start;
gap: 15px;
color: black;
height: auto;
// margin: 15px;
// padding: 15px;
button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
p{
    color: #AAA !important;
}
`
const SpecialNavBarLogout = styled(NavBarLogoutGeneral)`
    position:fixed;
    background-color: transparent !important;
`


const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: #F5F5F5 !important;
`

//HEADER STYLED
const DateText = styled.h4`
color: #000000/*#FBAE62*/;
`

const HeadingText = styled(motion.h1)`
color: black;
  margin-top: 0px;
  line-height: 95%;
  text-shadow: 2px 2px 2px #FFFFFF60; /* Creates a white shadow */
  font-weight: 600;
  margin-bottom: 0px;
`

const HeadingSubText = styled.h3`
color: #00000060;
margin: 10px 0px;;

`

const Author = styled.p`
font-size: .75em;
font-weight: 200;
margin: 0px;
color: black;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 3px;
  background-color: #AAAAAA;
  margin: 25px 0;
  max-width: 700px;
`;
//CONTENT CONTAINER STYLE
const BlogContentSection = styled(SECTION)`
// overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
// min-height: 100vh;
width: 100%;
// justify-content: start;
align-items: center;
// background-color: #E0CAA4;/*#F5F5F5;*/
`

const BlogHeaderContent = styled.div`
display: flex;
max-width: 700px;
flex-direction: column;
`
const BlogContent = styled.div`
max-width: 700px;
color: black;

p{
color: black;
}

`









const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long', // e.g., "Monday"
      month: 'long',   // e.g., "January"
      day: 'numeric',  // e.g., "1"
      year: 'numeric', // e.g., "2024"
    });
  };


  const renderContent = (content) => {
    return content.map((block, index) => {
      const sanitizedText = DOMPurify.sanitize(block.text); // Sanitize the HTML
      switch (block.type) {
        case "h1":
          return <BlogH1 key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "h2":
          return <BlogH2 key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "h3":
          return <BlogH3 key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "h4":
          return <BlogH4 key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "h5":
          return <BlogH5 key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "p":
          return <BlogP key={index} dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
        case "list":
          return (
            <BlogList key={index}>
              {block.items.map((item, idx) => (
                <BlogListItem key={idx} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }} />
              ))}
            </BlogList>
          );
        default:
          return null;
      }
    });
  };







  // Stylized components
const BlogH1 = styled.h1`
margin-top: 2em;
font-size: 2rem;
font-weight: bold;
color: #333;
`;

const BlogH2 = styled.h2`
margin-top: 1.75em;
font-size: 1.75rem;
font-weight: bold;
color: #444;
`;

const BlogH3 = styled.h3`
margin-top: 1.5em;
font-size: 1.5rem;
font-weight: normal;
color: #555;
`;

const BlogH4 = styled.h4`
margin-top: 1.25em;
font-size: 1.25rem;
font-weight: normal;
color: #666;
`;

const BlogH5 = styled.h5`
margin-top: 1em;
font-size: 1rem;
font-weight: normal;
color: #777;
`;

const BlogP = styled.p`
margin-top: 1em;
font-size: 1rem;
line-height: 1.5;
color: #333;
`;

const BlogList = styled.ul`
list-style-type: disc;
padding-left: 20px;
color: #444;
`;

const BlogListItem = styled.li`
font-size: 1rem;
color: #333;
margin-bottom: 8px;
`;
// const renderContent = (content) => {
//     return content.map((block, index) => {
//       switch (block.type) {
//         case "h1":
//           return <h1 key={index}>{block.text}</h1>;
//         case "h2":
//           return <h2 key={index}>{block.text}</h2>;
//         case "h3":
//           return <h3 key={index}>{block.text}</h3>;
//         case "h4":
//             return <h4 key={index}>{block.text}</h4>;
//         case "h5":
//           return <h5 key={index}>{block.text}</h5>;
//         case "p":
//           return <p key={index}>{block.text}</p>;
//         case "list":
//           return (
//             <ul key={index}>
//               {block.items.map((item, idx) => (
//                 <li key={idx}>{item}</li>
//               ))}
//             </ul>
//           );
//         default:
//           return null;
//       }
//     });
//   };