import styled from "styled-components";



//DELETE THIS ONE
export const PageContainer = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
height: 100dvh;
// width: 100vw;
// width: 100dvw;
background-color: white;
overflow-y: hidden;
overflow-x:hidden;
`


export const PageContentContainer = styled.div`
display:inline-block;
// flex-direction: column;
// flex: 1 1;
width: 100vw;
position:relative;
background-color: black;
`
